import alertDialog from './alertDialog.state';
import transferDialog from './transferDialog.state';
import analysis from './analysis.state';
import configurations from './configurations.state';
import eventDialog from './eventDialog.state';
import events from './events.state';
import matches from './matches.state';
import auth from './auth.state';
import video from './video.state';
import compiledVideos from './compiledVideos.state';
import ui from './ui.state';
import users from './users.state';
import teamConfigurations from './teamConfigurations.state';

const initialState = {
  alertDialog,
  transferDialog,
  analysis,
  configurations,
  eventDialog,
  events,
  matches,
  auth,
  video,
  compiledVideos,
  ui,
  users,
  teamConfigurations,
};

export default initialState;
