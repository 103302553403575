/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useState } from 'react';
import ArrowDown from '../../../assets/icons/down-arrow.svg';

export default function DropDown({
  items = [
    'Goleiro',
    'Zagueiro',
    'Lateral Esquerdo',
    'Lateral Direito',
    'Volante',
    'Ala Esquerdo',
    'Ala Direito',
    'Meio Campo Esquerdo',
    'Meio Campo Direito',
    'Meia Central',
    'Meia Lateral',
    'Atacante',
  ],
  onItemSelect,
  defaultSelected,
  label = 'Selecione',
}) {
  const [selectedItem, setSelectedItem] = useState('');
  const [isListVisible, setIsListVisible] = useState(false);

  if (defaultSelected) {
    if (!selectedItem) {
      setSelectedItem(defaultSelected);
    }
  }

  useEffect(() => {
    if (selectedItem) {
      onItemSelect(selectedItem);
    }
  }, [selectedItem]);

  return (
    <div
      onMouseLeave={() => {
        setIsListVisible(false);
      }}
      className="dropdown_container"
    >
      <div
        onClick={() => {
          setIsListVisible(prevState => !prevState);
        }}
        className="display_value"
      >
        {selectedItem ? selectedItem : label}
        <img
          className={isListVisible ? 'list-visible' : ''}
          src={ArrowDown}
          alt="Arrow down icon"
        />
      </div>
      {isListVisible ? (
        <ul>
          {items.map(currentItem => (
            <li
              onClick={() =>
                setSelectedItem(() => {
                  setIsListVisible(false);
                  return currentItem;
                })
              }
              key={currentItem}
            >
              {currentItem}
            </li>
          ))}
        </ul>
      ) : (
        false
      )}
    </div>
  );
}
