const common = {
  newMatchAnalysis: 'New match analysiss',
};

export const enUS = {
  drawer: {
    matches: 'Matches',
    newMatchAnalysis: common.newMatchAnalysis,
  },
  matches: {
    newMatchAnalysis: common.newMatchAnalysis,
    noMatchesToShow: 'No matches do show...',
    searchFildPlaceholder: 'Search your game...',
    dialogTitle: 'Do you want to create a new match?',
    dialogDescription:
      'You do not have a match created. Create a new match now.',
    dialogAgreeText: 'Create',
    dialogDesagreeText: 'Cancel',
  },
  newMatch: {
    dialogTitle: 'Creating the match and uploading the video...',
  },
  events: {
    eventListTitle: 'Events List',
  },
};

export default null;
