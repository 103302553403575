/* eslint-disable react-native/no-inline-styles */
import React, { useEffect, useState } from 'react';
import DropDown from './DropDown';
import { error } from '../../../utils/toast';
import Trash from '../../../assets/icons/trash.svg';
import Save from '../../../assets/icons/save.svg';

export default function PlayerItem({ data, onDelete, onUpload }) {
  const [playerData, setPlayerData] = useState(data);
  const validateNumberRegex = /^(?:[1-9]|[1-9][0-9])$/;

  useEffect(() => {
    if (playerData.name && playerData.gamePosition && playerData.numberPlayed) {
      const playerId = playerData.id;
      if (!`${playerId}`.startsWith('temp_')) {
        onUpload({
          playerId,
          data: playerData,
        });
      }
    }
  }, [playerData]);

  const selectPlayerPosition = e => {
    setPlayerData(prevState => ({
      ...prevState,
      gamePosition: e,
    }));
  };

  return (
    <li className="player-item">
      <input
        placeholder="Nome do jogador"
        value={playerData.name}
        onChange={e => {
          e.persist();

          setPlayerData(prevState => ({
            ...prevState,
            name: e.target.value,
          }));
        }}
      />
      <input
        style={{
          width: '60px',
        }}
        placeholder="Número"
        value={playerData.numberPlayed}
        onChange={e => {
          e.persist();

          const isNumberValid =
            validateNumberRegex.test(e.target.value) || e.target.value === '';

          if (isNumberValid) {
            setPlayerData(prevState => ({
              ...prevState,
              numberPlayed: e.target.value,
            }));
          }
        }}
      />
      <DropDown
        onItemSelect={selectPlayerPosition}
        defaultSelected={playerData.gamePosition}
        label="Selecione a posição"
      />
      <div
        style={{
          display: 'flex',
          columnGap: '6px',
        }}
      >
        {!data.isCreated ? (
          <button type="button">
            <img
              src={Save}
              alt="Trash icon"
              onClick={() => {
                if (playerData.name && playerData.gamePosition && playerData.numberPlayed) {
                  onUpload({
                    playerId: playerData.id,
                    data: playerData,
                    create: true,
                  });
                }
              }}
            />
          </button>
        ) : (
          false
        )}

        <button type="button">
          <img src={Trash} alt="Trash icon" onClick={onDelete} />
        </button>
      </div>
    </li>
  );
}
