export const setAlertDialog = (store, open, type) => {
  const alertDialog = {
    ...store.state.alertDialog,
    open,
    type: type || 'generic',
  };

  store.setState({ ...store.state, alertDialog });
};

export default null;
