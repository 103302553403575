/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Loader from 'react-loader-spinner';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Box, Grid, Typography } from '@material-ui/core';
import useGlobal from '../store';
import { language } from '../languages';

import DefaultDialog from '../components/dialogs/DefaultDialog.component';
import FormDialog from '../components/dialogs/FormDialog.component';
import TeamRow from '../components/TeamsConfigs/TeamRow';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: '12vh 0 0 0',
  },
  containerRoot: {
    border: '1px solid',
    borderRadius: 5,
  },
  textField: {
    width: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    marginLeft: 8,
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  input: {
    display: 'none',
  },
  contentClassName: {
    height: 200,
  },
}));

const defaultRowData = {
  id: '',
  name: '',
  gamePosition: '',
};

const ConfigTeam = ({ teamId }) => {
  if (Number.isNaN(Number(teamId)) && teamId !== 'create') {
    window.location.replace('../../teams');
  }

  const [globalState, globalActions] = useGlobal();
  const {
    fetchTeamConfigurations,
    createUpdatePlayer,
    deletePlayer,
    updateTeamConfigurations,
  } = globalActions;

  const [teamConfigurations, setTeamConfigurations] = useState([]);

  const [rowData, setRowData] = useState(defaultRowData);

  const [isLoading, setIsLoading] = useState(true);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    (async () => {
      const teams = await fetchTeamConfigurations();
      setTeamConfigurations(() => {
        if (teamId === 'create') {
          return [
            {
              name: '',
              abbreviation: '',
              description: '',
              season: '',
              logo: '',
            },
          ];
        }
        const filtered = teams.filter(
          currentTeam => currentTeam?.id === Number(teamId)
        );

        if (!filtered.length) {
          window.location.replace('../../teams');
        }

        return filtered;
      });
    })();
    setIsLoading(false);
  }, []);

  const handleFormChange = name => event =>
    setRowData({ ...rowData, [name]: event.target.value });

  const agreeOnClick = async () => {
    const data = {
      ...rowData,
      // teamConfiguration: {
      //   id: teamConfigurations.id,
      // },
    };

    createUpdatePlayer(data);
    setRowData(defaultRowData);
    setOpenDialog(false);
  };

  const disagreeOnClick = () => {
    setOpenDialog(false);
    setRowData(defaultRowData);
  };

  const agreeDeleteOnClick = async () => {
    deletePlayer(rowData.id);
    setOpenDeleteDialog(false);
  };

  return (
    <section
      style={{
        width: '100dvw',
        padding: '0 16px',
      }}
    >
      <div className={classes.root}>
        <h1
          style={{
            width: 'fit-content',
            padding: '1em 1em 0.25em 1em',
            borderBottom: '4px solid #a1c6ea',
            borderRadius: '4px',
          }}
        >
          {language.configurations.title}
        </h1>
        {isLoading ? (
          <Loader
            style={{
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '28vh',
            }}
            type="BallTriangle"
            color="#A1C6EA"
            height={80}
            width={80}
          />
        ) : (
          <ul
            style={{
              padding: '2vh 0',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <li
              style={{
                listStyleType: 'none',
              }}
            >
              {teamConfigurations.map(currentTeam => (
                <TeamRow team={currentTeam} key={currentTeam.id} full={true} />
              ))}
            </li>
          </ul>
        )}
      </div>

      <DefaultDialog
        title={language.matchCard.dialog.title}
        description={language.matchCard.dialog.description}
        agreeText={language.matchCard.dialog.agreeText}
        agreeOnClick={agreeDeleteOnClick}
        disagreeText={language.matchCard.dialog.disagreeText}
        disagreeOnClick={() => setOpenDeleteDialog(false)}
        open={openDeleteDialog}
      />
      <FormDialog
        agreeOnClick={agreeOnClick}
        contentClassName={classes.contentClassName}
        disagreeOnClick={disagreeOnClick}
        open={openDialog}
        title={language.configurations.formTitle}
      >
        <ValidatorForm onSubmit={() => {}}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12}>
              <TextValidator
                id="outlined-title"
                label={language.commons.name}
                className={classes.textField}
                value={rowData.name}
                onChange={handleFormChange('name')}
                margin="normal"
                variant="outlined"
                validators={['required', 'maxStringLength:50']}
                errorMessages={[
                  language.newMatch.required,
                  language.newMatch.validation.maxLength50,
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                id="outlined-title"
                label={language.commons.email}
                className={classes.textField}
                value={rowData.email}
                onChange={handleFormChange('email')}
                margin="normal"
                variant="outlined"
                validators={['required', 'maxStringLength:50']}
                errorMessages={[
                  language.newMatch.required,
                  language.newMatch.validation.maxLength50,
                ]}
              />
            </Grid>
          </Grid>
        </ValidatorForm>
      </FormDialog>
    </section>
  );
};

export default ConfigTeam;
