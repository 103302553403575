import React from 'react';
import Login from '../screens/Login.screen';
import Events from '../screens/Events.screen';
import Analysis from '../screens/Analysis.screen';
import Compiled from '../screens/Compiled.screen';
import ViewTeams from '../screens/ViewTeams.screen';
import ConfigTeam from '../screens/ConfigTeam.screen';
import ChangePassword from '../screens/ChangePassword.screen';
import ForgotPassword from '../screens/ForgotPassword.screen';
import Cadastro from '../screens/Cadastro.screen';
import AnalisePartida from '../screens/AnalisePartida.screen';

const routes = {
  '/login': () => <Login />,
  '/cadastro': () => <Cadastro />,
  '/analise-partida': () => <AnalisePartida />,
  '/events': () => <Events />,
  '/analysis/:matchId': ({ matchId }) => <Analysis matchId={matchId} />,
  '/compiled': () => <Compiled />,
  '/teams': () => <ViewTeams />,
  '/team/:teamId': ({ teamId }) => <ConfigTeam teamId={teamId} />,
  '/change-password': () => <ChangePassword email={null} userToken={null} />,
  '/change-password/:email/:userToken': ({ email, userToken }) => (
    <ChangePassword email={email} userToken={userToken} />
  ),
  '/forgot-password': () => <ForgotPassword />,
};

export default routes;
