import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';
import TransitionsModal from '../components/cards/Modal.component';
import Partidas from '../components/analise/Partidas.component';
import Matches from '../components/analise/Analisados.component';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box paddingY={6} paddingX={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: '12vh 0 0 0',
    minHeight: '100dvh',
  },
  root2: {
    flexGrow: 1,
  },
  tab: {
    color: 'var(--1-b-263-b, #1B263B)',
    fontSize: '24px',
    width: 'calc(100vw - 16px * 2)',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '8px',
    alignItems: 'end',
    flexWrap: 'wrap',
    whiteSpace: 'pre-wrap',
  },
  botaoModal: {
    width: 'fit-content',
    minWidth: '50px',
    height: 'fit-content',
    display: 'flex',
    alignItems: 'flex-end',
    margin: 'auto 0 auto auto',
  },
  customTab: {
    fontSize: '16px',
    textTransform: 'none',
    minWidth: '50px',
    paddingBottom: 0,
  },
}));

const AnalisePartida = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [isLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const indicatorStyle = {
    borderRadius: '50px',
    background: 'var(--a-1-c-6-ea, #A1C6EA)',
  };

  return (
    <div className={classes.root}>
      <Grid
        style={{
          padding: '0 16px',
        }}
        container
        spacing={3}
      >
        <Grid item xs={12}>
          <div
            className="pio"
            style={{
              padding: 0,
            }}
          >
            <Grid item xs={3}>
              <div className={classes.tab}>
                <Tabs
                  TabIndicatorProps={{ style: indicatorStyle }}
                  value={value}
                  onChange={handleChange}
                  style={{
                    display: 'flex',
                    gap: '24px',
                  }}
                >
                  <Tab
                    className={classes.customTab}
                    label="Analisados"
                    {...a11yProps(0)}
                  />
                </Tabs>
                <div className={classes.botaoModal}>
                  {value === 0 ? <TransitionsModal /> : false}
                </div>
              </div>
            </Grid>
            <TabPanel value={value} index={0}>
              <Matches />
            </TabPanel>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AnalisePartida;
