import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PauseIcon from '../../assets/images/pause.svg';

const useStyles = makeStyles(theme => ({
  button: {
    width: '130px',
    height: '50px',
    fontSize: '13px',
    fontWeight: 900,
  },
  icon: {
    width: '20px',
    height: '20px',
    position: 'absolute',
    right: '5px',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  buttonSecondy: {
    width: '130px',
    margin: 10,
    height: '50px',
    fontSize: '13px',
    fontWeight: 900,
  },
  // configuração d botão ativo
  homeActive: {
    color: 'black !important',
    boxShadow: '4px 4px 4px 0px rgba(0, 0, 0, 0.15)',
    border: '4px solid black !important',
  },

  // configuração d botão ativo
  awayActive: {
    color: 'black !important',
    boxShadow: '4px 4px 4px 0px rgba(0, 0, 0, 0.15)',
    border: '4px solid black !important',
  },
  side: {
    border: `1.2px solid ${theme.palette.gray.main}`,
  },
  bar: {
    padding: 0,
    height: 50,
    width: '-webkit-fill-available',
    marginRight: 5,
    marginLeft: 5,
    border: `1.2px solid ${theme.palette.gray.main}`,
  },
  imageContainerSide: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 15,
    width: '100%',
    justifyContent: 'space-evenly',
  },
  imageSide: {
    height: 40,
  },
  imageContainerBar: {
    flexDirection: 'column',
    fontSize: 8,
  },
  imageBar: {
    height: 30,
    display: 'block',
    margin: 'auto',
    marginBottom: 10,
  },
  importantActive: {
    backgroundColor: theme.palette.green.main,
    border: '4px solid black',
    '&:hover': {
      border: '4px solid black',
    },
  },
}));

const EventButton = ({
  text,
  type,
  context,
  onClick,
  active,
  disabled,
  color,
  fontColor,
  widthButton,
  heightButtom,
  paddingButtom,
  borderRadiusButtom,
}) => {
  const classes = useStyles();

  return context === 'side' ? (
    <Button
      style={{
        background: color,
        color: fontColor,
        width: widthButton,
        height: heightButtom,
        padding: paddingButtom,
        borderRadius: borderRadiusButtom,
      }}
      variant="contained"
      className={`${classes.buttonSecondy} ${active === 'home' &&
        classes.homeActive} ${active === 'away' && classes.awayActive} ${
        classes.side
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      <div>
        {text}
        {text === 'Pausa' ? (
          <img src={PauseIcon} alt="link partidas" className={classes.icon} />
        ) : (
          false
        )}
      </div>
    </Button>
  ) : (
    <Button
      variant="contained"
      style={{ background: color }}
      className={`${classes.button} ${(type === 'important' ||
        type === 'important-moviment') &&
        active &&
        classes.importantActive}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div>{text}</div>
    </Button>
  );
};

export default EventButton;
