/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react';
import ReactPlayer from 'react-player';

export default function VideoCard({
  url,
  videoBreak,
  onEventSelect,
  selected,
  isYoutubeVideo,
}) {
  function secondsToTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = padZero(hours);
    const formattedMinutes = padZero(minutes);
    const formattedSeconds = padZero(remainingSeconds);

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  function padZero(value) {
    return value < 10 ? `0${value}` : value;
  }

  const { title, time: start } = videoBreak;
  const end = start + 5;
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [playing, setPlaying] = useState(false);
  const player = useRef(null);

  const handleProgress = progress => {
    if (progress.playedSeconds >= end) {
      player.current.seekTo(start, 'seconds');
      setPlaying(false);
    }
  };

  const handleVideoStart = () => {
    // if (player.current) {
    //   player.current.seekTo(start, 'seconds');
    // }
  };

  if (player.current) {
    if (!playing) {
      player.current.seekTo(start, 'seconds');
    }
  }

  return (
    <>
      {playing ? (
        <div
          style={{
            width: '100dvw',
            height: '100dvh',
            position: 'fixed',
            left: 0,
            top: 0,
            background: '#000000aa',
            zIndex: 99,
          }}
        />
      ) : (
        false
      )}

      <div
        style={{
          ...(playing
            ? {
                transform: 'translate(-50%, -50%) scale(2)',
                position: 'fixed',
                left: '50%',
                top: '50%',
                zIndex: 100,
              }
            : {}),
        }}
        className={`videocard ${isVideoReady ? '' : 'loading-card'} ${
          selected ? 'selected' : ''
        }`}
      >
        {!isYoutubeVideo ? (
          <div className="header">
            <label for={videoBreak.id}>
              <input
                type="checkbox"
                id={videoBreak.id}
                checked={selected}
                onChange={e =>
                  onEventSelect({
                    [videoBreak.id]: {
                      isSelected: e.target.checked,
                      data: videoBreak,
                    },
                  })
                }
              />
              <span class="checkbox-container">
                {isVideoReady ? 'X' : null}
              </span>
            </label>
          </div>
        ) : (
          false
        )}

        <div className="video">
          <ReactPlayer
            url={url}
            ref={player}
            onReady={() => setIsVideoReady(true)}
            onStart={handleVideoStart}
            onPause={() => setPlaying(false)}
            onPlay={() => setPlaying(true)}
            width="95%"
            height="100%"
            onProgress={handleProgress}
            playing={playing}
            controls={true}
          />
        </div>
        {isVideoReady ? (
          <div className="video-data">
            <h5>{title}</h5>
            <span>
              {secondsToTime(start)} - {secondsToTime(end)}
            </span>
          </div>
        ) : (
          false
        )}
      </div>
    </>
  );
}
