import React from 'react';
import ReactDOM from 'react-dom';
import Favicon from 'react-favicon';
import { ThemeProvider } from '@material-ui/core/styles';
import App from './App';
import muiTheme from './theme/muiTheme';
import './styles/app.scss';

import logoViwa from './assets/icons/viwa_favicon.svg';

ReactDOM.render(
  <ThemeProvider theme={muiTheme}>
    <Favicon url={logoViwa} />
    <App />
  </ThemeProvider>,
  document.getElementById('app')
);

if (module.hot) module.hot.accept();
