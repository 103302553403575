/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState, memo } from 'react';
import VideoCard from './VideoCard';
import './index.css';

const Playlist = memo(function Playlist({
  url,
  breaks = [],
  onEventsSelect,
  isYoutubeVideo,
}) {
  const [selectedEvents, setSelectedEvents] = useState([]);

  const handleEventSelect = useCallback(event => {
    const id = Object.keys(event)[0];
    event[id].isSelected
      ? setSelectedEvents(prevEvents => [...prevEvents, id])
      : setSelectedEvents(prevEvents =>
          prevEvents.filter(currentEvent => currentEvent !== id)
        );
  }, []);

  useEffect(() => {
    if (!isYoutubeVideo) {
      onEventsSelect(selectedEvents);
    }
  }, [selectedEvents]);

  return (
    <div className="playlist-wrapper">
      {!isYoutubeVideo ? (
        <div className="header">
          <label htmlFor="selectAll">
            <input
              id="selectAll"
              type="checkbox"
              onChange={e => {
                if (e.target.checked) {
                  setSelectedEvents(() => {
                    const data = breaks.map(currentBreak => currentBreak.id);
                    return data;
                  });
                }
                if (!e.target.checked) {
                  setSelectedEvents([]);
                }
              }}
            />
            <span class="checkbox-container" />
            Selecionar todos os eventos
          </label>
        </div>
      ) : (
        false
      )}

      <div className="playlist">
        {breaks.map((currentBreak, index) => (
          <MemoizedVideoCard
            selected={selectedEvents.includes(currentBreak.id)}
            videoBreak={currentBreak}
            onEventSelect={handleEventSelect}
            url={url}
            key={`${currentBreak.id}_${index}`}
            isYoutubeVideo={isYoutubeVideo}
          />
        ))}
      </div>
    </div>
  );
});

const MemoizedVideoCard = React.memo(VideoCard);

export default Playlist;
