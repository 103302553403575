import requester from '../resources/requester';

export const cleanAuth = store => {
  const auth = {
    token: '',
    email: '',
    isAdmin: false,
    isSuperUser: false,
  };
  store.setState({ ...store.state, auth });
  localStorage.removeItem('auth');
};

export const setAuth = (store, property, value) => {
  const auth = {
    ...store.state.auth,
    [property]: value,
  };
  store.setState({ ...store.state, auth });
};

export const setAuthByLogin = (store, authData) => {
  const auth = {
    ...store.state.auth,
    ...authData,
  };
  store.setState({ ...store.state, auth });
};

export const login = async (store, navigate, user) => {
  const [error, response] = await requester('POST', '/auth/login', null, user);
  if (error) {
    console.error(error);
    store.actions.setAlertDialog(true, 'login');
  } else {
    setAuthByLogin(store, response.data);
    localStorage.setItem('auth', JSON.stringify(response.data));
    setAuth(store, 'token', response.data.token);
    store.actions.fetchTeamConfigurations();
    navigate('/analise-partida');
  }
};

export const logout = async store => {
  cleanAuth(store);
};

export const changePassword = (store, data) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async resolve => {
    try {
      const [error] = await requester(
        'POST',
        '/auth/changePassword',
        store.state.auth.token,
        data
      );
      if (error) {
        console.error(error);
        resolve({
          status: 500,
          message:
            'Ocorreu um problema ao trocar a senha. Por favor, tente novamente!',
        });
      } else {
        resolve({ status: 200, message: 'Sucesso ao trocar de senha' });
      }
    } catch (e) {
      console.error(e);
      resolve({
        status: 500,
        message:
          'Ocorreu um problema ao trocar a senha. Por favor, tente novamente!',
      });
    }
  });
};
