import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import ReactPlayer from 'react-player';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreIcon from '@material-ui/icons/MoreVert';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import ellipseUm from '../../assets/images/ellipseUm.png';
import Skeleton from '../skeleto/Skeleto.component';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 400,
    width: '100%',
    display: 'flex',
    flexDirection: 'column-reverse',
    height: '250px',
    border: '10px solid #edf7fe',
    background: '#edf7fe',
    padding: 0,
  },
  score: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& img': {
      width: 30,
      height: 30,
    },
    '& span': {
      margin: 10,
      fontSize: 15,
      fontWeight: 600,
      color: '#111',
    },
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: '#111',
  },
  info: {
    textAlign: 'left',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 10,
    marginBottom: 5,
    fontWeight: '500',
  },
  acronym: {
    color: '#111',
    fontWeight: '500',
  },
  date: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export default function MediaCard() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [isLoading, setIsLoading] = useState(true);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isLoading, setIsLoading] = useState(false);

  return (
    <section
      style={{
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <>
        {!isLoading ? (
          <Grid
            container
            spacing={2}
            style={{
              width: '100%',
            }}
          >
            <div
              style={{
                minWidth: '100%',
                display: 'flex',
                gap: '55px',
                rowGap: '32px',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {/* <Card className={classes.root}>
                <CardActionArea>
                  <ReactPlayer
                    className={classes.cardVideos}
                    url="https://youtu.be/ww1MA3b7j48"
                    height="auto"
                    width="100%"
                    config={{
                      youtube: {
                        playerVars: {
                          start: 1,
                          rel: 0,
                        },
                      },
                    }}
                  />
                  <CardContent>
                    <Grid container className={classes.matchContainer}>
                      <Grid className={classes.score} item xs={7} sm={7} md={5}>
                        <img src={ellipseUm} alt="Brazão Time" />
                        <span className={classes.result}> 1 x 1</span>
                        <img src={ellipseUm} alt="Brazão Time" />
                      </Grid>
                      <Grid
                        className={classes.infoContainer}
                        item
                        xs={3}
                        sm={3}
                        md={3}
                      >
                        <div className={`${classes.info} ${classes.acronym}`}>
                          AAA X BBB
                        </div>
                      </Grid>
                      <Grid className={classes.date} item xs={2.5}>
                        xx/xx/xx
                      </Grid>
                      <div>
                        <Button
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={handleClick}
                          className={classes.botaoSeletorDeopcoes}
                        >
                          <MoreIcon />
                        </Button>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          <MenuItem onClick={handleClose}>
                            Nova análise
                          </MenuItem>
                          <MenuItem onClick={handleClose}>Editar</MenuItem>
                          <MenuItem onClick={handleClose}>Excluir</MenuItem>
                        </Menu>
                      </div>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card className={classes.root}>
                <CardActionArea>
                  <ReactPlayer
                    className={classes.cardVideos}
                    url="https://youtu.be/ww1MA3b7j48"
                    height="auto"
                    width="100%"
                    config={{
                      youtube: {
                        playerVars: {
                          start: 1,
                          rel: 0,
                        },
                      },
                    }}
                  />
                  <CardContent>
                    <Grid container className={classes.matchContainer}>
                      <Grid className={classes.score} item xs={7} sm={7} md={5}>
                        <img src={ellipseUm} alt="Brazão Time" />
                        <span className={classes.result}> 1 x 1</span>
                        <img src={ellipseUm} alt="Brazão Time" />
                      </Grid>
                      <Grid
                        className={classes.infoContainer}
                        item
                        xs={3}
                        sm={3}
                        md={3}
                      >
                        <div className={`${classes.info} ${classes.acronym}`}>
                          AAA X BBB
                        </div>
                      </Grid>
                      <Grid className={classes.date} item xs={2.5}>
                        xx/xx/xx
                      </Grid>
                      <div>
                        <Button
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={handleClick}
                          className={classes.botaoSeletorDeopcoes}
                        >
                          <MoreIcon />
                        </Button>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          <MenuItem onClick={handleClose}>
                            Nova análise
                          </MenuItem>
                          <MenuItem onClick={handleClose}>Editar</MenuItem>
                          <MenuItem onClick={handleClose}>Excluir</MenuItem>
                        </Menu>
                      </div>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card> */}
            </div>
          </Grid>
        ) : (
          <Skeleton />
        )}
      </>
    </section>
  );
}
