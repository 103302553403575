export const setTransferDialog = (store, open) => {
  const transferDialog = {
    ...store.state.transferDialog,
    open,
  };

  store.setState({ ...store.state, transferDialog });
};

export const setTransferDialogProgress = (store, progress) => {
  const transferDialog = {
    ...store.state.transferDialog,
    progress,
  };

  store.setState({ ...store.state, transferDialog });
};

export const clearTransferDialog = store => {
  const transferDialog = {
    ...store.state.transferDialog,
    open: false,
    progress: 0,
  };

  store.setState({ ...store.state, transferDialog });
};

export const setFinishedTransferDialog = store => {
  const transferDialog = {
    ...store.state.transferDialog,
    finished: false,
  };

  store.setState({ ...store.state, transferDialog });
};

export const setHasVideo = (store, hasVideo) => {
  const transferDialog = {
    ...store.state.transferDialog,
    hasVideo,
  };

  store.setState({ ...store.state, transferDialog });
};

export default null;
