/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableGroupbar } from 'material-table';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import { success, error } from '../utils/toast';

import { language } from '../languages';
import useGlobal from '../store';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: '12vh 0 0 0',
    zIndex: '10',
  },
}));

const Compiled = () => {
  const [globalState, globalActions] = useGlobal();
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContentText, setDialogContentText] = useState('');
  const [actionType, setActionType] = useState(null);
  const [confirmedData, setConfirmedData] = useState(null);

  const [compiledVideos, setCompiledVideos] = useState([]);
  const columns = [
    {
      title: '',
      field: 'matchTitle',
      defaultGroupOrder: 0,
    },
    {
      title: language.compiled.tableTitle.compile.status,
      field: 'statusFormatted',
    },
    {
      title: language.compiled.tableTitle.compile.createdAt,
      field: 'createdAtFormatted',
    },
    {
      title: language.compiled.tableTitle.compile.description,
      field: 'compile',
    },
    {
      title: language.compiled.tableTitle.compile.createdBy,
      field: 'createdBy',
    },
    { title: language.compiled.tableTitle.match.title, field: 'title' },
    { title: language.compiled.tableTitle.match.result, field: 'result' },
    { title: language.compiled.tableTitle.match.date, field: 'date' },
    {
      title: language.compiled.tableTitle.match.description,
      field: 'description',
    },
  ];

  const classes = useStyles();
  const { getCompiledVideos } = globalActions;
  const { videos } = globalState.compiledVideos;

  useEffect(() => {
    getCompiledVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const compilesGroupedByMatch = videos.reduce((acc, item) => {
  //   const existingRow = acc.find(row => row.id === item.match.id);

  //   const compileData = {
  //     status: item.status,
  //     createdAt: item.createdAt,
  //     createdBy: item.createdBy,
  //     id: item.id,
  //     description: item.description,
  //   };

  //   if (existingRow) {
  //     return acc.map(row => {
  //       if (row.id === item.match.id) {
  //         return {
  //           ...row,
  //           compiles: [...row.compiles, compileData],
  //         };
  //       }
  //       return row;
  //     });
  //   }
  //   if (!existingRow) {
  //     acc.push({
  //       ...item.match,
  //       compiles: [compileData],
  //     });
  //     return acc;
  //   }
  // }, []);

  useEffect(() => {
    const data = [];
    videos.forEach(video => {
      data.push({
        id: video.id,
        matchTitle: `${video.match.home} ${video.match.homeScore}x${
          video.match.awayScore
        } ${video.match.away}`,
        createdAt: video.createdAt,
        createdAtFormatted: video.createdAt
          ? moment(video.createdAt).format('DD/MM/YYYY')
          : '',
        compile: video.description,
        status: video.status,
        statusFormatted: video.status
          ? language.compiled.status[video.status]
          : '',
        createdBy: video.createdBy,
        title: video.match.title,
        result: `${video.match.homeScore} x ${video.match.awayScore}`,
        date: moment(video.match.date).format('DD/MM/YYYY'),
        description: video.match.description,
      });
    });
    setCompiledVideos(data.sort((v1, v2) => v2.id - v1.id));
  }, [videos]);

  const onDownload = rowData => {
    setDialogTitle(`Deseja baixar o compilado '${rowData.compile}'?`);
    setDialogContentText(
      `O compilado foi criado em ${
        rowData.createdAt
          ? moment(rowData.createdAt).format('DD/MM/YYYY')
          : 'N/A'
      } por ${rowData.createdBy ? rowData.createdBy : 'N/A'}.`
    );
    setConfirmedData(rowData);
    setActionType('DOWNLOAD');
    setOpen(true);
  };

  const onDelete = rowData => {
    setDialogTitle(
      `Tem certeza que deseja deletar o compilado '${rowData.compile}'?`
    );
    setDialogContentText(
      `O compilado foi criado em ${
        rowData.createdAt
          ? moment(rowData.createdAt).format('DD/MM/YYYY')
          : 'N/A'
      } por ${rowData.createdBy ? rowData.createdBy : 'N/A'}.`
    );
    setConfirmedData(rowData);
    setActionType('DELETE');
    setOpen(true);
  };

  const onCancel = () => {
    setOpen(false);
  };

  const onConfirm = async () => {
    const data = confirmedData;
    if (actionType === 'DELETE') {
      const response = await globalActions.deleteVideoCompile(data.id);
      if (response.status === 500) {
        error('Ocorreu um erro ao deletar o vídeo!');
      } else if (response.status === 200) {
        setCompiledVideos(compiledVideos.filter(cv => cv.id !== data.id));
        success('O vídeo foi deletado com sucesso!');
      }
    } else if (actionType === 'DOWNLOAD') {
      window.open(await globalActions.getSignedUrl(data.id));
      success('Download iniciado com sucesso!');
    }
    setOpen(false);
  };

  return (
    <section
      style={{
        padding: 'clamp(16px, 5vw ,60px)',
      }}
    >
      <div className={classes.root}>
        <h1
          style={{
            width: 'fit-content',
            padding: '1em 1em 0.25em 1em',
            borderBottom: '4px solid #a1c6ea',
            borderRadius: '4px',
          }}
        >
          Compilados
        </h1>
        <MaterialTable
          title="0"
          columns={columns}
          data={compiledVideos}
          style={{
            padding: '12px 0',
            margin: '0 auto',
            boxShadow: 'none',
            botder: 'none',
            width: '100%',
            minHeight: '100%',
          }}
          options={{
            actionsColumnIndex: -1,
            grouping: false,
            showTitle: false,
            pageSize: 10,
            pageSizeOptions: [10, 20],
            toolbar: false,
            headerStyle: {
              borderBottom: '2px solid #a2c7ea',
              padding: '8px 0',
              fontWeight: 'bold',
              fontSize: '1em',
            },
          }}
          localization={{
            pagination: {
              labelDisplayedRows:
                language.compiled.pagination.labelDisplayedRows,
              labelRowsSelect: language.compiled.pagination.labelRowsSelect,
              labelRowsPerPage: language.compiled.pagination.labelRowsPerPage,
              firstAriaLabel: language.compiled.pagination.firstAriaLabel,
              firstTooltip: language.compiled.pagination.firstTooltip,
              previousAriaLabel: language.compiled.pagination.previousAriaLabel,
              previousTooltip: language.compiled.pagination.previousTooltip,
              nextAriaLabel: language.compiled.pagination.nextAriaLabel,
              nextTooltip: language.compiled.pagination.nextTooltip,
              lastAriaLabel: language.compiled.pagination.lastAriaLabel,
              lastTooltip: language.compiled.pagination.lastTooltip,
            },
            header: {
              actions: language.compiled.actions,
            },
            body: {
              emptyDataSourceMessage:
                language.compiled.body.emptyDataSourceMessage,
              filterRow: {
                filterTooltip: language.compiled.body.filterRow.filterTooltip,
              },
            },
            toolbar: {
              searchTooltip: language.compiled.toolbar.searchTooltip,
              searchPlaceholder: language.compiled.toolbar.searchPlaceholder,
            },
          }}
          actions={[
            rowData => ({
              icon: () => <CloudDownload />,
              tooltip: language.compiled.download,
              onClick: (event, data) => onDownload(data),
              disabled:
                rowData.status === 'CREATED' ||
                rowData.status === 'IN_PROGRESS',
            }),
            rowData => ({
              icon: 'delete',
              tooltip: language.compiled.delete,
              onClick: (event, data) => onDelete(data),
              disabled: rowData.status === 'IN_PROGRESS',
            }),
          ]}
        />
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{dialogContentText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} color="primary">
              {language.compiled.cancel}
            </Button>
            <Button onClick={onConfirm} color="primary">
              {language.compiled.confirm}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </section>
  );
};

export default Compiled;
