import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Doughnut } from 'react-chartjs-2';

import { language } from '../../languages';

import AnalysisMetric from '../progress/AnalysisMetric.component';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  analysisMetric: {
    flexBasis: '600px',
    padding: '8px 16px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    rowGap: '42px',
  },
  chartsWrapper: {
    flexBasis: '600px',
    padding: '8px 16px',
    flexGrow: 1,
    display: 'flex',
    flexWrap: 'wrap',
  },
  chart: {
    flexGrow: 1,
    width: '250px, 100%, 320px',
    maxWidth: '320px',
    flexBasis: '290px',
  },
  chartTitle: {
    textAlign: 'center',
    margin: '0 6px 30px 6px',
    borderBottom: '2px solid #a1c6ea',
    color: '#292a2d',
  },
  passesPrecision: {
    height: 50,
  },
  unavailableData: {
    color: '#292a2d',
    textAlign: 'center',
  },
}));

const AnalysisView = ({ analysisToShow, hidden }) => {
  const classes = useStyles();
  const metrics = [
    { name: 'shots', value: 'shots' },
    { name: 'shotsOnTarget', value: 'shots-on-target' },
    { name: 'shotsOffTarget', value: 'shots-off-target' },
    { name: 'offsides', value: 'offsides' },
    { name: 'cornersKick', value: 'corner-kicks' },
    { name: 'fouls', value: 'fouls' },
    { name: 'passes', value: 'passes' },
    { name: 'wrongPasses', value: 'wrong-passes' },
  ];

  const { home: homeData, away: awayData } = analysisToShow;
  const hasBallPossesion =
    homeData['ball-possession'] && awayData['ball-possession'];
  const passesPrecision =
    homeData['passes-precision'] && awayData['passes-precision'];

  const doughnut = {
    height: 75,
    width: 125,
    options: {
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) =>
            `${data.labels[tooltipItem.index]} ${
              data.datasets[0].data[tooltipItem.index]
            }%`,
        },
      },
    },
  };

  const getPercentageNumber = value => (value * 100).toFixed(0);

  const dataBallPossessionHandler = (home, away) => ({
    labels: [away.team, home.team],
    datasets: [
      {
        data: [getPercentageNumber(away.data), getPercentageNumber(home.data)],
        backgroundColor: ['#a1c6ea', '#1b263b'],
        hoverBackgroundColor: ['#a1c6ea', '#1b263b'],
      },
    ],
  });

  const dataPassesPrecisionHandler = (type, team, error) => {
    const color = type === 'home' ? '#1b263b' : '#a1c6ea';
    return {
      labels: [team.label, error.label],
      datasets: [
        {
          data: [
            getPercentageNumber(team.data),
            getPercentageNumber(error.data),
          ],
          backgroundColor: [color, '#3e5787'],
          hoverBackgroundColor: [color, '#3e5787'],
        },
      ],
    };
  };

  return (
    <>
      <div
        className={classes.container}
        style={{ display: `${hidden ? 'none' : ''}` }}
      >
        <div className={classes.analysisMetric}>
          {metrics.map(({ name, value }) => (
            <div>
              <AnalysisMetric
                metric={language.analysis[name]}
                home={homeData[value]}
                away={awayData[value]}
              />
            </div>
          ))}
        </div>
        <div className={classes.chartsWrapper}>
          <div className={classes.chart}>
            <h2 className={classes.chartTitle}>
              {language.analysis.ballPossession}
            </h2>
            {hasBallPossesion ? (
              <Doughnut
                data={dataBallPossessionHandler(
                  {
                    team: homeData.team,
                    data: homeData['ball-possession'],
                  },
                  {
                    team: awayData.team,
                    data: awayData['ball-possession'],
                  }
                )}
                width={doughnut.width}
                height={doughnut.height}
                options={doughnut.options}
              />
            ) : (
              <h5 className={classes.unavailableData}>
                {language.analysis.unavailableData}
              </h5>
            )}
          </div>
          <div className={classes.chart}>
            <h2 className={classes.chartTitle}>
              {language.analysis.passesPrecision}
            </h2>
            {passesPrecision ? (
              <>
                <Doughnut
                  data={dataPassesPrecisionHandler(
                    'home',
                    {
                      label: homeData.team,
                      data: homeData['passes-precision'].toFixed(2),
                    },
                    {
                      label: language.analysis.wrongPasses,
                      data: (1 - homeData['passes-precision']).toFixed(2),
                    }
                  )}
                  width={doughnut.width}
                  height={doughnut.height}
                  options={doughnut.options}
                />
                <div className={classes.passesPrecision} />
                <Doughnut
                  data={dataPassesPrecisionHandler(
                    'away',
                    {
                      label: awayData.team,
                      data: awayData['passes-precision'].toFixed(2),
                    },
                    {
                      label: language.analysis.wrongPasses,
                      data: (1 - awayData['passes-precision']).toFixed(2),
                    }
                  )}
                  width={doughnut.width}
                  height={doughnut.height}
                  options={doughnut.options}
                />
              </>
            ) : (
              <h5 className={classes.unavailableData}>
                {language.analysis.unavailableData}
              </h5>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalysisView;
