import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { navigate } from 'hookrouter';
import DeleteIcon from '@material-ui/icons/Delete';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import moment from 'moment';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Button,
  Card,
} from '@material-ui/core';
import {
  Player,
  BigPlayButton,
  LoadingSpinner,
  ControlBar,
  PlaybackRateMenuButton,
  Shortcut,
} from 'video-react';
import ReactPlayer from 'react-player';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { v4 as uuidv4 } from 'uuid';
import EventProgress from '../components/eventProgress/EventProgress.component';
import { language } from '../languages';
import ellipseUm from '../assets/images/ellipseUm.png';

import '../../node_modules/video-react/dist/video-react.css';
import useGlobal from '../store';
import { eventsHandler } from '../utils/events';

import PrimaryButton from '../components/buttons/PrimaryButton.component';
import EventButton from '../components/buttons/EventButton.component';
import EventDialog from '../components/dialogs/EventDialog.component';
import AlertDialog from '../components/dialogs/AlertDialog.component';
import { success } from '../utils/toast';

const uuid = uuidv4();

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: '12vh clamp(16px, 5vw, 60px) 16px clamp(16px, 5vw, 60px)',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100dvw',
    padding: '12px 16px',
    gap: 8,
    justifyContent: 'flex-end',
  },
  titulo: {
    color: '#000',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    flexGrow: 1,
    flexBasis: '100%',
  },
  containerSpan: {
    display: 'flex',
    gap: 'clamp(4px, 2vw,20px)',
    alignItems: 'start',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    margin: 10,
  },
  scoreText: {
    width: '70px',
    height: '25px',
    opacity: '0.4',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '6px',
    border: '1px solid #000',
  },
  botaoInicioFim: {
    display: 'flex',
  },
  placar: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
  },
  data: {
    margin: 10,
    color: '#000',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
  },
  eventButton: {
    display: 'flex',
    // gridTemplateColumns: 'repeat(3, auto)',
    justifyContent: 'center',
    gap: '0px',
    flexWrap: 'wrap',
    // placeSelf: 'end',
  },
  wrapEventButton: {
    // flexBasis: 'clamp(140px, 45%, 200px)',
    flexBasis: '140px',
    width: '50%',
    flexShrink: 2,
    maxWidth: '160px',
    flexGrow: 1,
    display: 'flex',
  },
  containerAccordion: {
    width: '100%',
    border: 'none',
    boxShadow: 'none',
    background: 'transparent',
    display: 'flex',
    flexDirection: 'column-reverse',
    rowGap: '4px',
  },
  iconeContainer: {
    borderRadius: '8px',
    border: '1px solid var(--1-b-263-b, #1B263B)',
    background: '#1B263B',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, auto)',
  },
  icone: {
    color: '#FFFFFF',
  },
  containerEventButton: {
    borderRadius: '20px',
    background: 'rgba(199, 221, 242, 0.26)',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 0,
    justifyContent: 'center',
    padding: 20,
    width: '100%',
  },
  containerSalvar: {
    display: 'flex',
    columnGap: 8,
    width: 'clamp(200px, 100%, 800px)',
    justifyContent: 'center',
  },
  completeAnalysis: {
    borderRadius: '10px',
    background: 'var(--1-b-263-b, #1B263B)',
    color: '#FFF',
    width: '300px',
    fontSize: 'clamp(12px, 3vw, 15px)',
    textTransform: 'none',
    padding: '10px',
    '&:hover': {
      backgroundColor: 'var(--a-1-c-6-ea, #A1C6EA)',
      border: 'none',
    },
  },
  tituloEventos: {
    color: '#000',
    textAlign: 'center',
    fontSize: '26px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
  },
  eventsList: {
    display: 'grid',
    justifyContent: 'center',
    width: '250px, 90dvw, 700px',
  },
  containerEventList: {
    background: 'rgba(199, 221, 242, 0.26)',
    borderRadius: '20px',
    display: 'grid',
    justifyContent: 'center',
    width: '250px, 90dvw, 700px',
    flexGrow: 1,
  },
  actionsContainer: {
    flexGrow: 1,
    flexBasis: '560px',
    width: 'clamp(240px, 10dvw, 300px)',
  },
  videoContainer: {
    flexGrow: 1,
    flexBasis: '58%',
    width: 'clamp(240px, 90dvw, 800px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  video: {
    width: 'clamp(240px, 90dvw, 800px)',
    minHeight: '200px',
    AspectRatio: '16 / 9',
    position: 'relative',
    paddingTop: '56.25%',
  },
  'react-player': {
    position: 'absolute',
    top: '0',
    left: '0',
  },
  buttonPosseDeBola: {
    display: 'flex',
    justifyContent: 'center',
  },
  possessionButton: {
    flexGrow: 1,
  },
}));

const Events = () => {
  const classes = useStyles();
  const [globalState, globalActions] = useGlobal();
  const [videoTime, setVideoTime] = useState(0);
  const {
    id,
    home,
    away,
    awayScore,
    homeScore,
    date,
  } = globalState.matches.selected;
  const { finished, hasVideo } = globalState.transferDialog;
  const { clearEvents, clearTransferDialog } = globalActions;
  const [localState, localSetState] = useState({ ballPossession: null });
  const { ballPossession } = localState;
  const video = globalState.video.selected;
  const { link } = globalState.video;
  const eventsList = globalState.events.events;

  const [timePlaying, setTimePlaying] = useState(false);
  const [importantState, importantSetState] = useState({
    active: false,
    time: 0,
  });

  const format = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  };

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  useEffect(() => {
    if (importantState.active) {
      const interval = setInterval(() => {
        importantSetState(prevState => ({
          ...prevState,
          time: prevState.time + 1,
        }));
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [importantState.active]);

  useEffect(() => {
    clearEvents();
  }, [clearEvents]);

  useEffect(() => {
    if (finished) {
      clearTransferDialog();
      globalActions.setHasVideo(false);
      success(language.toast.videoUpload);
    }
  }, [clearTransferDialog, finished, globalActions]);

  const getTime = () => {
    if (link) {
      return videoTime;
    } else if (video && document.querySelector('div[data-current-time]')) {
      return document
        .querySelector('div[data-current-time]')
        .getAttribute('data-current-time');
    } else if (!video && document.getElementById('timer')) {
      return document.getElementById('timer').innerHTML;
    }
    return '00:00';
  };

  const onProgress = ({ playedSeconds }) => {
    const m = moment().utcOffset(0);
    m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    m.add(playedSeconds, 'seconds');
    m.toISOString();
    setVideoTime(m.format('HH:mm:ss'));
  };

  const playPauseVideo = (shoulStart = false) => {
    if (video) {
      document.querySelector('button.video-react-play-control').click();
    } else {
      // Se não tiver link nem video, o time será o marcador
      if (timePlaying) document.getElementById('pauseButton').click();
      else if (!timePlaying && shoulStart)
        document.getElementById('startButton').click();
    }
  };

  const eventsButtons = [
    {
      text: language.events.pass,
      color: '#a1c6ea',
      type: 'pass',
      onClick: () =>
        globalActions.createEvent(
          'pass',
          ballPossession,
          null,
          getTime(),
          uuidv4(),
          null
        ),
    },
    {
      text: language.events.pause,
      type: 'pause',
      color: '#a1c6ea',
      onClick: () => {
        globalActions.createEvent(
          'pause',
          ballPossession,
          null,
          getTime(),
          uuidv4(),
          null
        );
        localSetState({ ballPossession: null });
      },
    },
    {
      text: language.events.wrongPass,
      color: '#a1c6ea',
      type: 'wrong-pass',
      onClick: () => {
        globalActions.createEvent(
          'wrong-pass',
          ballPossession,
          null,
          getTime(),
          uuidv4(),
          null
        );
        const newPossession = ballPossession === 'home' ? 'away' : 'home';
        localSetState({ ballPossession: newPossession });
        globalActions.createEvent(
          'ball-possession',
          newPossession,
          null,
          getTime(),
          uuidv4(),
          null
        );
      },
    },
    {
      text: language.events.shot,
      type: 'shot',
      color: '#7ed957',
      onClick: () => {
        globalActions.setEventDialog(true, {
          event: 'shot',
          eventName: 'shot',
          team: ballPossession,
          time: getTime(),
          uuid: uuidv4(),
          callback: () => playPauseVideo(true),
        });
      },
    },
    {
      text: language.events.transition,
      type: 'transition',
      color: '#ffbd59',
      onClick: () => {
        playPauseVideo();
        globalActions.setEventDialog(true, {
          event: 'transition',
          eventName: 'transition',
          team: ballPossession,
          time: getTime(),
          uuid: uuidv4(),
          callback: () => playPauseVideo(true),
        });
      },
    },
    {
      text: language.events.important,
      type: 'important',
      color: '#37b6ff',
      onClick: () => {
        playPauseVideo();
        globalActions.setEventDialog(true, {
          event: 'important',
          eventName: 'important',
          team: ballPossession === 'home' ? 'home' : 'away',
          time: getTime(),
          uuid: uuidv4(),
          duration: importantState.time,
          callback: () => playPauseVideo(true),
        });
      },
    },
  ];

  const barEventsButtons = [
    {
      text: language.events.cornerKick,
      color: '#b88917',
      type: 'corner-kick',
      onClick: () =>
        globalActions.createEvent(
          'corner-kick',
          ballPossession,
          null,
          getTime(),
          uuidv4(),
          null
        ),
    },
    {
      text: language.events.foul,
      type: 'foul',
      color: '#ffd51c',
      onClick: () => {
        const ballUnpossession = ballPossession === 'home' ? 'away' : 'home';
        playPauseVideo();
        globalActions.setEventDialog(true, {
          event: 'foul',
          eventName: 'foul',
          team: ballUnpossession,
          time: getTime(),
          uuid: uuidv4(),
          callback: () => playPauseVideo(true),
        });
      },
    },
    {
      text: language.events.penaltyKick,
      type: 'penalty-kick',
      color: '#ed3f32',
      onClick: () => {
        playPauseVideo();
        globalActions.setEventDialog(true, {
          event: 'penaltyKick',
          eventName: 'penalty-kick',
          team: ballPossession,
          time: getTime(),
          uuid: uuidv4(),
          callback: () => playPauseVideo(true),
        });
      },
    },
    {
      text: language.events.cross,
      type: 'cross',
      color: '#a1c6ea',
      onClick: () =>
        globalActions.createEvent(
          'cross',
          ballPossession,
          null,
          getTime(),
          uuidv4(),
          null
        ),
    },
    {
      text: language.events.longPass,
      type: 'long-pass',
      color: '#a1c6ea',
      onClick: () =>
        globalActions.createEvent(
          'long-pass',
          ballPossession,
          null,
          getTime(),
          uuidv4(),
          null
        ),
    },
    {
      text: language.events.offside,
      type: 'offside',
      color: '#a1c6ea',
      onClick: () => {
        playPauseVideo();
        globalActions.setEventDialog(true, {
          event: 'offside',
          eventName: 'offside',
          team: ballPossession,
          time: getTime(),
          uuid: uuidv4(),
          callback: () => playPauseVideo(true),
        });
      },
    },
    {
      text: language.events.tackle,
      color: '#a1c6ea',
      type: 'tackle',
      onClick: () => {
        globalActions.createEvent(
          'tackle',
          ballPossession,
          null,
          getTime(),
          uuidv4(),
          null
        );
        const newPossession = ballPossession === 'home' ? 'away' : 'home';
        localSetState({ ballPossession: newPossession });
        globalActions.createEvent(
          'ball-possession',
          ballPossession,
          null,
          getTime(),
          uuidv4(),
          null
        );
      },
    },
    {
      text: language.events.card,
      type: 'card',
      color: '#a1c6ea',
      onClick: () => {
        playPauseVideo();
        globalActions.setEventDialog(true, {
          event: 'card',
          eventName: 'card',
          team: ballPossession === 'home' ? 'home' : 'away',
          time: getTime(),
          uuid: uuidv4(),
          callback: () => playPauseVideo(true),
        });
      },
    },
    {
      text: language.events.kickOff,
      type: 'kick-off',
      color: '#a1c6ea',
      onClick: () => {
        playPauseVideo();
        globalActions.setEventDialog(true, {
          event: 'kickOff',
          eventName: 'kick-off',
          team: ballPossession,
          time: getTime(),
          uuid: uuidv4(),
          callback: () => playPauseVideo(true),
        });
      },
    },
    {
      text: language.events.goalkeeper,
      type: 'goalkeeper',
      color: '#a1c6ea',
      onClick: () =>
        globalActions.createEvent(
          'goalkeeper',
          ballPossession,
          null,
          getTime(),
          uuidv4(),
          null
        ),
    },

    {
      text: language.events.stoppedBall,
      type: 'stopped-ball',
      color: '#a1c6ea',
      onClick: () => {
        globalActions.createEvent(
          'stopped-ball',
          ballPossession,
          null,
          getTime(),
          uuidv4(),
          null
        );
        localSetState({ ballPossession: null });
      },
    },
  ];

  const botaoInicioFim = {
    text: language.events.startEnd,
    widthButton: '100%',
    heightButtom: '30%',
    type: 'start-end',
    fontColor: '#FFF',
    color: '#1B263B',
    onClick: () =>
      globalActions.createEvent(
        'start-end',
        ballPossession,
        null,
        getTime(),
        uuidv4(),
        null
      ),
  };

  const ballPossessionHome = {
    text: `${home} ${language.events.ballPossession}`,
    type: 'ball-possession-home',
    borderRadiusButtom: '6px',
    padding: '8px',
    widthButton: '45%',
    heightButtom: '60px',
    color: '#a1c6ea',
    team: ballPossession === null || ballPossession,
    active: ballPossession === 'home' ? 'home' : null,
    disabled: ballPossession === 'home',
    time: getTime(),
    onClick: () => {
      localSetState({ ballPossession: 'home' });
      globalActions.createEvent(
        'ball-possession',
        'home',
        null,
        getTime(),
        uuidv4(),
        null
      );
    },
  };
  const ballPossessionAway = {
    text: `${away} ${language.events.ballPossession}`,
    type: 'ball-possession-away',
    color: '#a1c6ea',
    padding: '8px',
    heightButtom: '60px',
    widthButton: '45%',
    borderRadiusButtom: '8px',
    team: ballPossession === null || ballPossession,
    active: ballPossession === 'away' ? 'away' : null,
    disabled: ballPossession === 'away',
    time: getTime(),
    onClick: () => {
      localSetState({ ballPossession: 'away' });
      globalActions.createEvent(
        'ball-possession',
        'away',
        null,
        getTime(),
        uuidv4(),
        null
      );
    },
  };

  const getEventText = (time, team, type, extraInfo) => {
    let eventType = type;
    if (type === 'card' && extraInfo && JSON.parse(extraInfo).card) {
      eventType = JSON.parse(extraInfo).card;
    }

    const teamName = team === 'home' ? home : away;
    const eventName =
      type === 'important'
        ? JSON.parse(extraInfo).name
        : `${language.events[eventsHandler(eventType)]}`;

    return `${time} - ${teamName} # ${eventName}`;
  };

  const orderEvent = (a, b) => {
    if (b.createdAt > a.createdAt) return 1;
    return -1;
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const videoRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => {
    if (globalState.eventDialog.open) {
      setIsVideoPlaying(false);
    }
    if (!globalState.eventDialog.open) {
      setIsVideoPlaying(true);
    }
  }, [globalState.eventDialog]);

  return (
    <section className={classes.container}>
      <div className={classes.root}>
        <div className={classes.videoContainer}>
          <div className={classes.containerSpan}>
            <div className={classes.placar}>
              <img
                src={ellipseUm}
                alt="Brazão Time"
                className={classes.brazaoTime}
              />
              <span className={classes.scoreText}>{home}</span>{' '}
              <span className={classes.scoreText}>
                {homeScore} x {awayScore}{' '}
              </span>{' '}
              <span className={classes.scoreText}>{away}</span>
              <img
                src={ellipseUm}
                alt="Brazão Time"
                className={classes.brazaoTime}
              />
            </div>
            <span className={classes.data}>
              {new Date(date).toLocaleString('pt-BR', format)}
            </span>
            <span>
              <EventButton
                text={botaoInicioFim.text}
                widthButton={botaoInicioFim.widthButton}
                type={botaoInicioFim.type}
                heightButtom={botaoInicioFim.heightButtom}
                fontColor={botaoInicioFim.fontColor}
                color={botaoInicioFim.color}
                context="side"
                active={botaoInicioFim.active}
                disabled={botaoInicioFim.desabled}
                onClick={
                  botaoInicioFim.type.includes('ball-possession') ||
                  ballPossession !== null
                    ? () => botaoInicioFim.onClick()
                    : () =>
                        globalActions.setAlertDialog(
                          true,
                          'ballPossessionNotSelected'
                        )
                }
              />
            </span>
          </div>
          <Grid className={classes.video}>
            {link ? (
              <ReactPlayer
                url={link}
                controls
                width="100%"
                ref={videoRef}
                playing={isVideoPlaying}
                height="100%"
                className={classes['react-player']}
                style={{
                  margin: 'auto',
                }}
                onProgress={onProgress}
                config={{
                  youtube: {
                    playerVars: {
                      start: 1,
                    },
                  },
                }}
              />
            ) : (
              video && (
                <Player
                  className={classes['react-player']}
                  style={{
                    margin: 'auto',
                  }}
                  src={video}
                >
                  <BigPlayButton position="center" />
                  <LoadingSpinner />
                  <ControlBar>
                    <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                  </ControlBar>
                  <Shortcut />
                </Player>
              )
            )}
          </Grid>
          <Grid>
            <EventProgress />
          </Grid>
          <div className={classes.containerSalvar}>
            <PrimaryButton
              variant="outlined"
              className={classes.completeAnalysis}
              text="Salvar análise da partida"
              onClick={
                hasVideo && !finished && link !== null
                  ? () =>
                      globalActions.setAlertDialog(
                        true,
                        'videoUploadNotFinished'
                      )
                  : () => {
                      globalActions.createEvent(
                        'end-match',
                        null,
                        null,
                        getTime()
                      );
                      globalActions.finishMatch(id);
                      globalActions.setFinishedTransferDialog();
                      globalActions.setHasVideo(false);
                      navigate(`/analysis/${id}`);
                    }
              }
            />
            <Button
              variant="outlined"
              className={classes.completeAnalysis}
              href="/analise-partida"
            >
              Cancelar
            </Button>
          </div>
        </div>
        <div className={classes.actionsContainer}>
          <div className={classes.containerEventButton}>
            <div className={classes.buttonPosseDeBola}>
              <EventButton
                className={classes.possessionButton}
                text={ballPossessionHome.text}
                widthButton="50%"
                type={ballPossessionHome.type}
                color={ballPossessionHome.color}
                context="side"
                active={ballPossessionHome.active}
                disabled={ballPossessionHome.disabled}
                onClick={
                  ballPossessionHome.type.includes('ball-possession') ||
                  ballPossessionHome !== null
                    ? () => ballPossessionHome.onClick()
                    : () =>
                        globalActions.setAlertDialog(
                          true,
                          'ballPossessionNotSelected'
                        )
                }
              />
              <EventButton
                className={classes.possessionButton}
                widthButton="50%"
                text={ballPossessionAway.text}
                type={ballPossessionAway.type}
                color={ballPossessionAway.color}
                context="side"
                active={ballPossessionAway.active}
                disabled={ballPossessionAway.desabled}
                onClick={
                  ballPossessionAway.type.includes('ball-possession') ||
                  ballPossessionAway !== null
                    ? () => ballPossessionAway.onClick()
                    : () =>
                        globalActions.setAlertDialog(
                          true,
                          'ballPossessionNotSelected'
                        )
                }
              />
            </div>
            <div className={classes.eventButton}>
              {eventsButtons.map(
                ({ type, text, active, disabled, onClick, color }) => (
                  <div className={classes.wrapEventButton}>
                    <EventButton
                      text={text}
                      type={type}
                      color={color}
                      context="side"
                      active={active}
                      disabled={disabled}
                      onClick={() => {
                        if (text === 'Pausa') {
                          setIsVideoPlaying(false);
                        }
                        if (
                          type.includes('ball-possession') ||
                          ballPossession !== null
                        ) {
                          onClick();
                        } else {
                          globalActions.setAlertDialog(
                            true,
                            'ballPossessionNotSelected'
                          );
                        }
                      }}
                    />
                  </div>
                )
              )}
            </div>
            <Accordion className={classes.containerAccordion}>
              <AccordionSummary
                onClick={() => setIsAccordionOpen(prevState => !prevState)}
                className={classes.iconeContainer}
                expandIcon={
                  !isAccordionOpen ? (
                    <AddIcon fontSize="large" className={classes.icone} />
                  ) : (
                    <RemoveIcon fontSize="large" className={classes.icone} />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              />
              <div className={classes.eventButton}>
                {barEventsButtons.map(({ text, type, onClick, color }) => {
                  return type === 'important' && ballPossession !== null ? (
                    <div
                      style={{
                        padding: '13px',
                      }}
                      className={classes.wrapEventButton}
                    >
                      <EventButton
                        text={
                          importantState.active === false
                            ? text
                            : `${importantState.time} seg`
                        }
                        type={
                          importantState.active === false
                            ? type
                            : 'important-moviment'
                        }
                        active={importantState.active}
                        onClick={
                          importantState.active === false
                            ? () =>
                                importantSetState({
                                  ...importantState,
                                  active: true,
                                })
                            : () => {
                                onClick();
                                importantSetState({ active: false, time: 0 });
                              }
                        }
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        padding: '13px',
                      }}
                      className={classes.wrapEventButton}
                    >
                      <EventButton
                        text={text}
                        type={type}
                        color={color}
                        onClick={
                          ballPossession !== null
                            ? () => onClick()
                            : () =>
                                globalActions.setAlertDialog(
                                  true,
                                  'ballPossessionNotSelected'
                                )
                        }
                      />
                    </div>
                  );
                })}
              </div>
              <AlertDialog />
              <EventDialog />
            </Accordion>
          </div>
        </div>
        <Grid item xs={7} />
        <div className={classes.containerEventList}>
          <List dense={false} className={classes.eventsList}>
            <h1 className={classes.tituloEventos}>Lista De Eventos</h1>
            {eventsList
              .sort(orderEvent)
              .map(({ team, time, type, uuid, extraInfo }) => (
                <Fragment key={uuid}>
                  <ListItem>
                    <ListItemText>
                      {getEventText(time, team, type, extraInfo)}
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => globalActions.deleteEvent(uuid)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </Fragment>
              ))}
          </List>
        </div>
      </div>
    </section>
  );
};

export default Events;
