/* eslint-disable jsx-a11y/label-has-associated-control */
import { Card, Button } from '@material-ui/core';
import { navigate } from 'hookrouter';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Loader from 'react-loader-spinner';
import { language } from '../../languages';
import useGlobal from '../../store';
import PrimaryButton from '../buttons/PrimaryButton.component';
import logoViwa from '../../assets/icons/viwa_logo.svg';
import './index.css';

const LoginCard = () => {
  const [, globalActions] = useGlobal();
  const [isLoading, setIsLoading] = useState(false);
  const [localState, localSetState] = useState({
    email: '',
    password: '',
    disabled: true,
  });

  useEffect(() => {
    if (true) {
      localSetState({ ...localState, disabled: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState.email, localState.password]);

  const handleChange = name => event =>
    localSetState({ ...localState, [name]: event.target.value });

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await globalActions.login(navigate, localState);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Card className="login_card">
      <div className="align_img">
        <img src={logoViwa} alt="logo" className="logo" />
      </div>
      {isLoading ? (
        <Loader
          className="loader"
          type="BallTriangle"
          color="#A1C6EA"
          height={70}
          width={70}
        />
      ) : (
        <>
          <Typography variant="h3" gutterBottom className="saudacao">
            Bem-vindo
          </Typography>
          <ValidatorForm onSubmit={handleSubmit}>
            <div className="inputs">
              <label className="labelEmail" for="email">
                E-mail
              </label>
              <TextValidator
                id="email"
                variant="outlined"
                margin="dense"
                className="input"
                value={localState.email}
                onChange={handleChange('email')}
                validators={['required']}
                errorMessages={[language.login.required]}
              />

              <label className="labelSenha" for="password">
                Senha
              </label>
              <TextValidator
                id="password"
                margin="dense"
                variant="outlined"
                className="input"
                value={localState.password}
                onChange={handleChange('password')}
                name="password"
                type="password"
                validators={['required']}
                errorMessages={[language.login.required]}
              />
              <a href="/forgot-password">
                <span className="logonText">Esqueceu sua senha?</span>
              </a>
            </div>
            <PrimaryButton
              text="Entrar"
              variant="contained"
              color="primary"
              type="submit"
              className="loginButton"
            />
          </ValidatorForm>
          <h5 className="logon">
            {/* <span className="logonText">Ainda não é membro?</span> */}
            {/* <Button
              type="button"
              onClick={() => window.location.replace('/cadastro')}
              className="textCadastro"
            >
              cadastre-se agora!
            </Button> */}
          </h5>
        </>
      )}
    </Card>
  );
};

export default LoginCard;
