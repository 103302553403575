import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import style from './index.css';
import PlayerItem from './PlayerItem';
import { baseURL } from '../../../resources/config';
import useGlobal from '../../../store';
import { error } from '../../../utils/toast';

export default function PlayersModal({ data, team, onClose, uploadPlayers }) {
  const [players, setPlayers] = useState(data.length ? data : []);

  const saveData = () => {
    uploadPlayers(players);
  };

  const [globalState] = useGlobal();
  const { auth } = globalState;

  const removePlayer = async playerId => {
    if (!`${playerId}`.startsWith('temp_')) {
      await axios.request({
        method: 'DELETE',
        url: `${baseURL}/players/${playerId}`,
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
    }

    setPlayers(prevState =>
      prevState.filter(currentPlayer => currentPlayer?.id !== playerId)
    );
  };

  function generateShortUUID() {
    const randomFourDigits = Math.floor(Math.random() * 10000);
    const shortUUID = randomFourDigits.toString().padStart(4, '0');
    return shortUUID;
  }

  const updatePlayerData = useCallback(async e => {
    const { playerId, data, create } = e;
    const { name, gamePosition } = data;
    let { numberPlayed } = data;

    const hasPlayerWithSameNumber = players.find(
      player =>
        Number(player.numberPlayed) === Number(numberPlayed) &&
        player.id !== playerId
    );

    if (hasPlayerWithSameNumber) {
      error('Esse número já é utilizado!');
    }
    if (!hasPlayerWithSameNumber) {
      if (`${playerId}`.startsWith('temp_') && team.id && create) {
        await axios.request({
          method: 'POST',
          url: `${baseURL}/players`,
          data: {
            teamConfiguration: {
              id: team.id,
            },
            name,
            gamePosition,
            numberPlayed,
          },
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });

        const { data: playersData, status } = await axios.request({
          method: 'GET',
          url: `${baseURL}/players/team/${team.id}`,
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        if (status === 200) {
          setPlayers(
            playersData.map(row => ({
              ...row,
              isCreated: true,
            }))
          );
        }
      }

      if (!`${playerId}`.startsWith('temp_')) {
        await axios.request({
          method: 'PUT',
          url: `${baseURL}/players/${playerId}`,
          data: {
            name,
            gamePosition,
            numberPlayed,
          },
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
      }

      setPlayers(prevState => {
        const playerIndex = prevState.findIndex(
          currentPlayer => currentPlayer.id === playerId
        );
        const newPlayers = [...prevState];
        const hasSameNumber = prevState.some(
          player =>
            player.numberPlayed === numberPlayed && player.id !== playerId
        );
        numberPlayed = hasSameNumber ? '' : numberPlayed;
        if (hasSameNumber) {
          error('Esse número já é utilizado!');
        }

        if (playerIndex !== -1) {
          newPlayers[playerIndex] = {
            ...data,
            numberPlayed,
            isCreated: true && !hasSameNumber,
          };
        }
        return newPlayers;
      });
    }
  }, []);

  const addPlayer = () => {
    setPlayers(prevState => {
      if (
        prevState.length &&
        prevState[prevState.length - 1]?.name &&
        prevState[prevState.length - 1]?.gamePosition &&
        prevState[prevState.length - 1]?.isCreated
      ) {
        return [
          ...prevState.map(row => ({
            ...row,
            isCreated: true,
          })),
          {
            id: `temp_${generateShortUUID()}`,
            name: '',
            gamePosition: '',
            isCreated: false,
            numberPlayed: '',
          },
        ];
      } else if (!prevState.length) {
        return [
          {
            id: `temp_${generateShortUUID()}`,
            name: '',
            gamePosition: '',
            isCreated: false,
            numberPlayed: '',
          },
        ];
      } else {
        return {
          ...prevState,
          isCreated: true,
        };
      }
    });
  };

  useEffect(() => {
    uploadPlayers(
      players.filter(
        currentPlayer =>
          currentPlayer.name &&
          currentPlayer.gamePosition &&
          currentPlayer.numberPlayed
      )
    );
  }, [players]);

  return (
    <div className="modal-wrapper">
      <div className="modal-body">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            height: 'fit-content',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: 'fit-content',
              columnGap: '12px',
            }}
          >
            <div className="teams_logo">
              {team.logo ? <img src={team.logo} alt="teams logo" /> : false}
            </div>
            <h1>{team.name}</h1>
          </div>
          <h1 onClick={() => onClose()} className="close-modal">
            X
          </h1>
        </div>
        <ul
          style={{
            margin: '24px 0',
            padding: '0 4px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '16px',
            overflowY: 'auto',
            overflowX: 'hidden',
            height: '100%',
          }}
        >
          {players.map(currentPlayer => (
            <PlayerItem
              team={team}
              data={currentPlayer}
              key={currentPlayer.id}
              onDelete={() => removePlayer(currentPlayer.id)}
              onUpload={updatePlayerData}
            />
          ))}
          <button
            type="button"
            disabled={
              players.length
                ? !(
                    players[players.length - 1].name &&
                    players[players.length - 1].gamePosition &&
                    players[players.length - 1].isCreated
                  )
                : false
            }
            className="addplayer_button"
            onClick={addPlayer}
          >
            +
          </button>
        </ul>
      </div>
    </div>
  );
}
