import moment from 'moment';
import requester from '../resources/requester';

export const createEvent = async (
  store,
  type,
  team,
  player,
  time,
  uuid,
  extraInfo
) => {
  const data = {
    matchId: store.state.matches.selected.id,
    type,
    team,
    player,
    time,
    uuid,
    extraInfo: extraInfo ? JSON.stringify(extraInfo) : extraInfo,
    createdAt: moment().valueOf(),
  };

  if (type === 'goal' || type === 'kill') {
    const events = {
      ...store.state.events,
      [`${team}Score`]: store.state.events[`${team}Score`] + 1,
      events: [...store.state.events.events, data],
    };
    store.setState({ ...store.state, events });
  } else {
    const events = {
      ...store.state.events,
      events: [...store.state.events.events, data],
    };
    store.setState({ ...store.state, events });
  }
  const [,] = await requester('POST', '/events', store.state.auth.token, data);
};

export const deleteEvent = async (store, uuid) => {
  const [error] = await requester(
    'DELETE',
    `/events/${uuid}`,
    store.state.auth.token
  );
  if (!error) {
    const eventList = store.state.events.events.filter(e => {
      if (e.uuid === uuid) return false;
      return true;
    });
    const events = {
      ...store.state.events,
      events: eventList,
    };
    store.setState({ ...store.state, events });
  }
};

export const clearEvents = store => {
  const events = {
    ...store.state.events,
    homeScore: 0,
    awayScore: 0,
    events: [],
    team: null,
  };
  store.setState({ ...store.state, events });
};

export default null;
