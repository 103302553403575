import videoUpload from '../resources/videoUpload';
import requester from '../resources/requester';

const path = require('path');
const uuidv4 = require('uuid/v4');

export const setVideo = (store, type) => {
  const video = {
    ...store.state.video,
    type,
  };
  store.setState({ ...store.state, video });
};

export const updateVideo = (store, property, value) => {
  try {
    const video = {
      ...store.state.video,
      [property]: value,
    };
    store.setState({ ...store.state, video });
  } catch (error) {
    console.error(error);
  }
};

const uploadVideoToCloudStorage = async (
  store,
  token,
  videoData,
  matchData
) => {
  try {
    if (videoData && videoData.name) {
      const endpoint = `/matches/${matchData.id}/videos`;
      const [error, response] = await videoUpload(
        'POST',
        endpoint,
        token,
        videoData
      );
      if (error) {
        console.error(JSON.stringify(error));
      } else {
        console.log(response);
      }
    }
  } catch (e) {
    console.error(e);
  }
};

const createLinkVideo = async (link, matchData, token) => {
  try {
    const endpoint = `/matches/${matchData.id}/links`;
    const [error, response] = await requester('POST', endpoint, token, {
      islink: true,
      link,
    });
    if (error) {
      console.error(JSON.stringify(error));
    } else {
      console.log(response);
    }
  } catch (e) {
    console.error(e);
  }
};

export const uploadVideo = (store, videoData, link, matchData) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const { token } = store.state.auth;
      if (link) {
        updateVideo(store, 'link', link);
        await createLinkVideo(link, matchData, token);
      } else if (videoData) {
        updateVideo(store, 'selected', URL.createObjectURL(videoData));
        await uploadVideoToCloudStorage(store, token, videoData, matchData);
      } else {
        updateVideo(store, 'selected', null);
        updateVideo(store, 'link', null);
      }
      resolve();
    } catch (e) {
      console.error('Error to upload video', e);
      reject(e);
    }
  });
};

// const createVideo = async (videoName, videoData, link, matchData, token) => {
//   const [error, response] = await requester('POST', '/videos', token, {
//     name: videoName,
//     size: videoData.size,
//     type: videoData.type,
//     match: {
//       id: matchData.id,
//     },
//   });
//   if (error) {
//     console.error(`Error: ${error}`);
//   } else {
//     console.log(response);
//   }
// };
