import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import MoreIcon from '@material-ui/icons/MoreVert';
import axios from 'axios';
import { navigate } from 'hookrouter';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '../skeleto/Skeleto.component';
import ellipseUm from '../../assets/images/ellipseUm.png';

import { baseURL } from '../../resources/config';
import { language } from '../../languages';
import useGlobal from '../../store';
import { error, success } from '../../utils/toast';
import DefaultDialog from '../dialogs/DefaultDialog.component';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 400,
    width: '100%',
    display: 'flex',
    flexDirection: 'column-reverse',
    height: '250px',
    border: '10px solid #edf7fe',
    background: '#edf7fe',
    margin: '0 auto',
  },
  score: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: 30,
      height: 30,
    },
    '& span': {
      margin: 10,
      fontSize: 15,
      fontWeight: 600,
      color: '#111',
    },
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: '#111',
  },
  info: {
    textAlign: 'left',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 10,
    marginBottom: 5,
    fontWeight: '500',
  },
  acronym: {
    color: '#111',
    fontWeight: '500',
  },

  date: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const MatchCard = ({ match, onDelete, videoData }) => {
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1200);
  }, [match]);

  const [, globalActions] = useGlobal();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const open = Boolean(anchorEl);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlerDelete = () => {
    setOpenDialog(true);
    setAnchorEl(null);
  };

  const onClick = () => {
    globalActions.updateMatches('selected', match);
    navigate(`/analysis/${match.id}`);
  };

  const agreeOnClick = async () => {
    setOpenDialog(false);
    const response = await globalActions.deleteMatch(match.id);
    if (response.status === 500) error(response.message);
    else {
      onDelete(match.id);
      success(response.message);
    }
    globalActions.setShowLoader(false);
  };

  const disagreeOnClick = () => {
    setOpenDialog(false);
  };

  const [globalState] = useGlobal();
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      {!isLoading ? (
        <Card className={classes.root}>
          <CardActionArea>
            {videoData ? (
              <ReactPlayer
                className={classes.cardVideos}
                height="auto"
                width="100%"
                url={videoData.link}
                key={videoData.id}
                config={{
                  youtube: {
                    playerVars: {
                      start: 1,
                      rel: 0,
                    },
                  },
                }}
              />
            ) : (
              <Skeleton />
            )}
            <CardContent>
              <Grid
                container
                className={classes.matchContainer}
                onClick={onClick}
              >
                <Grid className={classes.score} item xs={7} sm={7} md={5}>
                  <img src={ellipseUm} alt={match.home} />
                  <span className={classes.result}>{`${match.homeScore} x ${
                    match.awayScore
                  }`}</span>
                  <img src={ellipseUm} alt={match.away} />
                </Grid>
                <Grid
                  className={classes.infoContainer}
                  item
                  xs={3}
                  sm={3}
                  md={3}
                >
                  <div className={`${classes.info} ${classes.title}`}>
                    {match.title}
                  </div>
                  <div className={`${classes.info} ${classes.acronym}`}>
                    {`${match.home} x ${match.away}`}
                  </div>
                </Grid>
                <Grid className={classes.date} item xs={2.5}>
                  {moment(match.date).format('DD/MM/YYYY')}
                </Grid>
                <IconButton edge="end" color="inherit" onClick={handleMenu}>
                  <MoreIcon fontSize="large" />
                </IconButton>
              </Grid>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handlerDelete}>Deletar</MenuItem>
              </Menu>
              <DefaultDialog
                title={language.matchCard.dialog.title}
                description={language.matchCard.dialog.description}
                agreeText={language.matchCard.dialog.agreeText}
                agreeOnClick={agreeOnClick}
                disagreeText={language.matchCard.dialog.disagreeText}
                disagreeOnClick={disagreeOnClick}
                open={openDialog}
              />
            </CardContent>
          </CardActionArea>
        </Card>
      ) : (
        <Skeleton />
      )}
    </>
  );
};

export default MatchCard;
