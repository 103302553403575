import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import LoginCard from '../components/Login';

const Login = () => {
  const useStyles = makeStyles(theme => ({
    container: {
      height: '100%',
      background: 'var(--a-1-c-6-ea, #A1C6EA)',
      padding: 'unset',
    },
  }));

  const classes = useStyles();

  return (
    <section className={classes.container}>
      <LoginCard />
    </section>
  );
};

export default Login;
