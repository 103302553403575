/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { navigate } from 'hookrouter';
import { makeStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Grid from '@material-ui/core/Grid';
import InputMask from 'react-input-mask';
import { useDropzone } from 'react-dropzone';
import Loader from 'react-loader-spinner';

import 'react-datepicker/dist/react-datepicker.css';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import useGlobal from '../../store';
import { language } from '../../languages';
import { dateFormatter } from '../../utils/formatter';
import ellipseUm from '../../assets/images/ellipseUm.png';
import uploadIcon from '../../assets/images/uploadIcon.svg';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: 20,
    marginBottom: 20,
  },

  data: {
    textDecoration: 'none',
    display: 'grid',
  },
  textField: {
    textAlign: 'center',
    fontSize: 10,
    display: 'flex',
    border: 'unset',
    gap: 20,
  },
  botaoUpload: {
    width: 70,
  },
  containerButtonNewMatch: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
  },
  newMatch: {
    marginTop: '6px',
    marginLeft: '4px',
    borderRadius: '16px',
    background: '#1B263B',
    padding: '5px 5px',
  },
  newMatchFrase: {
    color: '#FFF',
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoWrapperHome: {
    marginRight: -15,
  },
  logo: {
    height: 40,
    width: 40,
  },
  dropzoneContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    border: `1px solid ${theme.palette.gray.main}`,
    background: theme.palette.gray.light,
    color: theme.palette.green.main,
    outline: 'none',
    transition: 'border .24s ease-in-out',
    height: 'clamp(150px, 20vw,200px)',
    width: '100%',
    cursor: 'pointer',
  },
  dropzoneButton: {
    paddingTop: 50,
    border: 'unset',
    backgroundColor: ' unset ',
  },
  dropzoneButtonIcone: {
    width: '55%',
  },
  dropzoneText: {
    opacity: '0.25',
    color: '#111111',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: 'normal',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
  },
  paper: {
    borderRadius: '16px',
    background: 'var(--ffffff, #FFF)',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 3, 3),
    position: 'relative',
    width: 'clamp(300px, 90dvw, 550px)',
    maxHeight: '550px !important',
    outline: 'none',
  },

  titulo: {
    color: '#000',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    paddingBottom: 20,
  },
  iconeBotao: {
    border: 'unset',
    backgroundColor: 'unset',
    justifyContent: 'center',
  },

  modalText: {
    background: 'var(--a-1-c-6-ea, #A1C6EA)',
    borderRadius: '8px ',
    border: '0px',
    padding: '6px 8px',
    cursor: 'pointer',
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  button: {
    color: '#FFF',
    textAlign: 'center',
    fontSize: '15px',
    border: 'none',
    borderRadius: '8px',
    background: 'var(--1-b-263-b, #1B263B)',
    padding: '10px',
    width: 200,
  },
  containerButton: {
    justifyContent: 'center',
    padding: 20,
    display: 'flex',
    gap: 10,
  },
  botaoFechar: {
    border: 'unset',
    backgroundColor: 'unset',
    position: 'absolute',
    top: '8px',
    right: '8px',
    color: '#1B263B',
    background: '#FFF',
    display: 'flex',
    padding: '15px 21px',
    cursor: 'pointer',
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    margin: 0,
    width: '100%',
    height: '100%',
  },
  mascara: {
    marginRight: 5,
    paddingRight: 5,
  },
  teamScoreWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    flexBasis: '33%',
    flexWrap: 'wrap',
    rowGap: '0px',
  },
  teamAbbreviation: {
    maxWidth: '70px !important',
    flexBasis: '70px',
  },
}));

const TransitionsModal = () => {
  const classes = useStyles();
  const [modalOpen, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: 'video/mp4, video/x-m4v, video/*',
  });

  const [globalState, globalActions] = useGlobal();
  const { teamConfigurations } = globalState;
  const { general } = teamConfigurations;
  const [localState, localSetState] = useState({
    title: '',
    date: dateFormatter(new Date()),
    dateObj: new Date(),
    homeLogo: general.logo || ellipseUm,
    home: general.abbreviation || '',
    homeScore: '',
    score: '',
    awayLogo: ellipseUm,
    awayScore: '',
    away: '',
    description: '',
    link: '',
  });
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    globalActions.setHasVideo(false);
  }, []);

  useEffect(() => {
    localSetState({ ...localState, home: localState.home.toUpperCase() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState.home]);

  useEffect(() => {
    const scores = localState.score.split(' x ');
    const homeScore = scores[0] ? scores[0].trim() : '';
    const awayScore = scores[1] ? scores[1].trim() : '';
    localSetState({ ...localState, homeScore, awayScore });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState.score]);

  useEffect(() => {
    localSetState({ ...localState, away: localState.away.toUpperCase() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState.away]);

  useEffect(() => {
    localSetState({ ...localState, file: acceptedFiles[0] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  const handleChange = name => event =>
    localSetState({ ...localState, [name]: event.target.value });
  const handleDateChange = dateObj =>
    localSetState({ ...localState, dateObj, date: dateFormatter(dateObj) });

  const handleSwap = () =>
    localSetState({
      ...localState,
      homeLogo: localState.awayLogo,
      home: localState.away,
      awayLogo: localState.homeLogo,
      away: localState.home,
    });
  const onSubmit = async () => {
    setLoading(true);
    await globalActions.createMatch(localState);
    setLoading(false);
    navigate('/events');

    if (localState && localState.file && localState.file.name) {
      globalActions.setHasVideo(true);
      globalActions.setTransferDialog(true);
    } else {
      globalActions.setHasVideo(false);
    }
  };

  return (
    <div>
      <button type="button" onClick={handleOpen} className={classes.modalText}>
        <AddIcon fontSize="medium" className={classes.iconeBotao} />
        Novo vídeo
      </button>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <div className={classes.paper}>
            <button
              type="button"
              onClick={handleClose}
              className={classes.botaoFechar}
            >
              <CloseIcon />
            </button>
            <section
              style={{
                width: '100%',
                padding: 0,
              }}
            >
              {loading ? (
                <Loader
                  className="loader"
                  type="BallTriangle"
                  color="#A1C6EA"
                  height={80}
                  width={80}
                />
              ) : (
                <>
                  <ValidatorForm className={classes.form} onSubmit={onSubmit}>
                    <Grid
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        width: '100% !important',
                      }}
                      container
                      spacing={12}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'flex-end',
                          gap: '4px',
                        }}
                      >
                        <div className={classes.teamScoreWrapper}>
                          <img
                            src={localState.homeLogo}
                            alt={localState.home}
                            className={classes.logo}
                          />

                          <InputMask
                            mask="aaa"
                            disabled={localState.home === general.abbreviation}
                            value={localState.home}
                            onChange={handleChange('home')}
                          >
                            {() => (
                              <TextValidator
                                id="outlined-home"
                                // label="DDD"
                                // className={classes.textField}
                                margin="normal"
                                placeholder="DDD"
                                variant="outlined"
                                size="small"
                                className={classes.teamAbbreviation}
                                validators={[
                                  'required',
                                  // 'matchRegexp:^[A-Z]{3}$',
                                ]}
                                // errorMessages={[
                                //   language.newMatch.required,
                                //   language.newMatch.validation.teamAcronym,
                                // ]}
                              />
                            )}
                          </InputMask>
                        </div>

                        <InputMask
                          mask="9 x 9"
                          value={localState.score}
                          onChange={handleChange('score')}
                        >
                          {() => (
                            <TextValidator
                              id="outlined-score"
                              size="small"
                              placeholder="placar"
                              // label={language.newMatch.score}
                              // className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              className={classes.teamAbbreviation}
                              style={{
                                flexBasis: '33%',
                              }}
                              validators={[
                                'required',
                                // 'matchRegexp:^[0-9] x [0-9]$',
                              ]}
                              // errorMessages={[
                              //   language.newMatch.required,
                              //   language.newMatch.validation.score,
                              // ]}
                            />
                          )}
                        </InputMask>
                        <div
                          style={{
                            flexWrap: 'wrap-reverse',
                          }}
                          className={classes.teamScoreWrapper}
                        >
                          <InputMask
                            mask="aaa"
                            disabled={localState.away === general.abbreviation}
                            value={localState.away}
                            onChange={handleChange('away')}
                          >
                            {() => (
                              <TextValidator
                                id="outlined-away"
                                size="small"
                                placeholder="GGG"
                                // label="GGG"
                                // className={classes.textField}
                                margin="normal"
                                variant="outlined"
                                className={classes.teamAbbreviation}
                                validators={[
                                  'required',
                                  // 'matchRegexp:^[A-Z]{3}$',
                                ]}
                                // errorMessages={[
                                //   language.newMatch.required,
                                //   language.newMatch.validation.teamAcronym,
                                // ]}
                              />
                            )}
                          </InputMask>
                          <img
                            src={localState.awayLogo}
                            alt={localState.away}
                            className={classes.logo}
                          />
                        </div>
                      </div>
                      <TextValidator
                        id="Link"
                        label="Link"
                        className={classes.textField}
                        value={localState.link}
                        onChange={handleChange('link')}
                        margin="normal"
                        variant="outlined"
                        style={{
                          flexBasis: '100%',
                        }}
                      />
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <aside className={classes.dropzoneFile}>
                          <p>{localState.file ? localState.file.name : null}</p>
                        </aside>
                        <div
                          {...getRootProps({
                            className: classes.dropzoneContainer,
                          })}
                        >
                          <input {...getInputProps()} />
                          <button
                            type="button"
                            styles={classes.textField}
                            onClick={open}
                            text={language.newMatch.buttonVideo}
                            className={classes.dropzoneButton}
                          >
                            <img
                              src={uploadIcon}
                              alt="Botão de Upload"
                              className={classes.dropzoneButtonIcone}
                            />
                          </button>
                          <p className={classes.dropzoneText}>
                            Arraste e solte seu vídeo aqui...
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={0}
                      className={classes.containerButton}
                    >
                      <button
                        variant="outlined"
                        color="primary"
                        type="submit"
                        className={classes.button}
                      >
                        Criar análise da partida
                      </button>
                    </Grid>
                  </ValidatorForm>
                </>
              )}
            </section>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default TransitionsModal;
