import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 10,
    backgroundColor: theme.palette.auxiliary.two,
  },
  bar: {
    backgroundColor: theme.palette.auxiliary.one,
  },
}))(LinearProgress);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  infoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  info: {
    fontSize: 15,
    color: 'black',
    fontWeight: 500,
  },
  infoNumber: {
    fontSize: 18,
  },
  progressLine: {
    borderRadius: 10,
  },
}));

const AnalysisMetric = ({ metric, home, away }) => {
  const classes = useStyles();

  const calculateValue = () => {
    if (home === 0 && away > 0) return 0;
    if (home > 0 && away === 0) return 100;
    return (home / (home + away)) * 100 || 50;
  };

  return (
    <div className={classes.root}>
      <div className={classes.infoWrapper}>
        <div className={`${classes.info} ${classes.infoNumber}`}>{home}</div>
        <div className={classes.info}>{metric}</div>
        <div className={`${classes.info} ${classes.infoNumber}`}>{away}</div>
      </div>
      <BorderLinearProgress
        className={classes.progressLine}
        variant="determinate"
        value={calculateValue()}
      />
    </div>
  );
};

export default AnalysisMetric;
