import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const showToast = (type, message) => {
  const options = {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: true,
    background: 'blue',
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  };
  if (type === 'SUCCESS') {
    toast.success(message, options);
  } else if (type === 'ERROR') {
    toast.error(message, options);
  }
};

export const success = message => showToast('SUCCESS', message);
export const error = message => showToast('ERROR', message);

export default null;
