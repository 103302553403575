import React from 'react';
import './Style.css';

const Skeleton = () => {
  return (
    <div class="card loading">
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum
        reiciendis similique a. Temporibus asperiores error ullam quae delectus
        necessitatibus neque dolorem iste, itaque voluptatibus, laborum, aliquid
        enim molestias rem quo?
      </p>
    </div>
  );
};

export default Skeleton;
