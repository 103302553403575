import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import useGlobal from '../../store';
import { language } from '../../languages';

const AlertDialog = () => {
  const [globalState, globalActions] = useGlobal();
  const { open, type } = globalState.alertDialog;

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {language.alertDialog[type].title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {language.alertDialog[type].description.split('\n').map(item => (
            <p key={item}>{item}</p>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => globalActions.setAlertDialog(false)}
          color="primary"
          autoFocus
        >
          {language.alertDialog.agree}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
