/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card } from '@material-ui/core';
import { navigate } from 'hookrouter';
import useGlobal from '../../store';
import { language } from '../../languages';
import MatchCard from '../cards/MatchCard.component';
import DefaultDialog from '../dialogs/DefaultDialog.component';
import { baseURL } from '../../resources/config';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: 20,
    marginBottom: 50,
    width: '100%',
    padding: 0,
  },
  container: {
    justifyContent: 'space-between',
    marginTop: 20,
  },
  newMatch: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  newMatchButton: {
    width: '100%',
  },
  paperSearch: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  inputSearch: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  nav: {
    justifyContent: 'center',
    display: 'grid',
    margin: 30,
  },
}));

const Matches = () => {
  const [globalState, globalActions] = useGlobal();
  const { token } = globalState.auth;
  const { fetchMatches } = globalActions;

  const [localState, localSetState] = useState({
    loaded: false,
    matchesToShow: [],
  });

  const [filteredMatches, setFilteredMatches] = useState([]);
  const [videos, setVideos] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const { loaded, matchesToShow } = localState;

  useEffect(() => {
    (async () => {
      const matches = await fetchMatches();
      if (matches) {
        matches.sort((m1, m2) => (m1.date < m2.date ? 1 : -1));
        localSetState({ ...localState, loaded: true, matchesToShow: matches });
        setFilteredMatches(matches);
        if (matches && matches.length === 0) setOpenDialog(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  const disagreeOnClick = () => setOpenDialog(false);
  const agreeOnClick = () => {
    setOpenDialog(false);
    navigate('/new-match');
  };

  const onSearch = value => {
    const matchesFiltered = matchesToShow.filter(match => {
      return (
        match.home.toLowerCase().includes(value.toLowerCase()) ||
        match.away.toLowerCase().includes(value.toLowerCase()) ||
        match.title.toLowerCase().includes(value.toLowerCase()) ||
        `${match.home.toLowerCase()} x ${match.away.toLowerCase()}`.includes(
          value.toLowerCase()
        ) ||
        (match.description &&
          match.description.toLowerCase().includes(value.toLowerCase()))
      );
    });
    setFilteredMatches(matchesFiltered);
  };

  // deletar
  const onDelete = id => {
    setFilteredMatches(
      filteredMatches.filter(currentMatch => currentMatch.id !== id)
    );
    localSetState({
      loaded: true,
      matchesToShow: matchesToShow.filter(
        currentMatch => currentMatch.id !== id
      ),
    });
  };

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(`${baseURL}/videos`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setVideos(data);
    })();
  }, []);

  return (
    <section
      style={{
        padding: 0,
      }}
    >
      {loaded ? (
        <>
          <div className={classes.root}>
            <Grid
              container
              spacing={2}
              style={{
                width: '100%',
                padding: 0,
              }}
            >
              {filteredMatches.length > 0
                ? filteredMatches.map(match => (
                    <Grid
                      key={match.id}
                      item
                      xl={3}
                      lg={4}
                      md={6}
                      xs={12}
                      wrap="wrap"
                      style={{
                        width: '100%',
                      }}
                    >
                      <MatchCard
                        match={match}
                        onDelete={onDelete}
                        videoData={
                          videos.filter(video => video.match.id === match.id)[0]
                        }
                      />
                    </Grid>
                  ))
                : false}
            </Grid>
          </div>
        </>
      ) : (
        <Loader
          type="BallTriangle"
          color="#A1C6EA"
          height={95}
          width={95}
          // eslint-disable-next-line react-native/no-inline-styles
          style={{
            margin: '150px 0 0 0',
            width: '80px !imporant',
            height: '80px !imporant',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      )}
    </section>
  );
};

export default Matches;
