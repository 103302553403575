import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import IconVideo from '../../assets/icons/IconVideo.svg';
import Vector from '../../assets/icons/Vector.svg';
import IconPasta from '../../assets/icons/IconPasta.svg';
import IconLog from '../../assets/icons/IconLog.svg';
import WhiteIconVideo from '../../assets/icons/whiteIcons/IconVideo.svg';
import WhiteVector from '../../assets/icons/whiteIcons/Vector.svg';
import WhiteIconPasta from '../../assets/icons/whiteIcons/IconPasta.svg';
import WhiteIconLog from '../../assets/icons/whiteIcons/IconLog.svg';
import Hamburger from '../../assets/icons/hamburger.svg';
import logoViwaWhite from '../../assets/icons/logoViwa.svg';
import logoViwaBlack from '../../assets/icons/logoViwaBlack.svg';
import useGlobal from '../../store';
import BotaoLogout from '../../assets/icons/BotaoLogout.svg';
import './index.css';

const useStyles = makeStyles(theme => ({
  containerAppBar: {
    borderRadius: '16px',
    background: 'var(--a-1-c-6-ea, #A1C6EA)',
    boxShadow: '0px 8px 30px 0px rgba(0, 0, 0, 0.15)',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  barraDeNavegacao: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '12px',
    width: '100%',
    height: '8.5vh',
    zIndex: '20',
  },
  container: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  link: {
    display: 'block',
    maxWidth: '80%',
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  logoViwa: {
    display: 'block',
    maxWidth: '100%',
  },
  search: {
    position: 'relative',
    padding: '0 8px 0 34px',
    borderRadius: '16px',
    opacity: '0.4',
    background: 'var(--ffffff, #FFF)',
    marginLeft: 0,
    width: 'calc(90% - 34px - 8px)',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    width: '30px',
    position: 'absolute',
    left: '4px',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
  },
  inputInput: {
    width: '300px',
    fontSize: '1.2em',
  },
}));

export default function SearchField({ pathName }) {
  const classes = useStyles();
  const [globalState, globalActions] = useGlobal();
  const { toggleDrawer, cleanAuth } = globalActions;
  const [isMobileToolsOpen, setIsMobileToolsOpen] = useState(false);

  const items = [
    {
      path: '/analise-partida',
      alt: 'link partidas',
      blackIcon: IconVideo,
      whiteIcon: WhiteIconVideo,
      name: 'Partidas',
    },
    {
      path: '/teams',
      alt: 'link cadastro de time',
      blackIcon: Vector,
      whiteIcon: WhiteVector,
      name: 'Times',
    },
    {
      path: '/compiled',
      alt: 'link para vídeos compilados',
      blackIcon: IconPasta,
      whiteIcon: WhiteIconPasta,
      name: 'Compilados',
    },
    {
      path: '/change-password',
      alt: 'link para troca de senha',
      blackIcon: IconLog,
      whiteIcon: WhiteIconLog,
      name: 'Perfil',
    },
  ];

  return (
    <div className="barraDeNavegacao">
      <div className="root">
        <AppBar position="static" className="containerAppBar">
          <Toolbar className={classes.container}>
            <div className={classes.containerLogo}>
              <img className="logoWhite" src={logoViwaWhite} alt="logo" />
              <img className="logoBlack" src={logoViwaBlack} alt="logo" />
            </div>
            <div className="desktop_tools">
              {items.slice(0, 2).map(currentItem => (
                <a href={currentItem.path} key={currentItem.path}>
                  <img
                    src={
                      currentItem.path === pathName
                        ? currentItem.whiteIcon
                        : currentItem.blackIcon
                    }
                    alt={currentItem.alt}
                    className={classes.link}
                  />
                </a>
              ))}
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon className={classes.icone} />
                </div>
                <InputBase
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </div>
              {items.slice(2, 4).map(currentItem => (
                <a href={currentItem.path} key={currentItem.path}>
                  <img
                    src={
                      currentItem.path === pathName
                        ? currentItem.whiteIcon
                        : currentItem.blackIcon
                    }
                    alt={currentItem.alt}
                    className={classes.link}
                  />
                </a>
              ))}
              <Button
                variant="text"
                onClick={async () => {
                  await cleanAuth();
                  window.location.replace('/login');
                }}
              >
                <img
                  src={BotaoLogout}
                  alt="link de saida da aplicação "
                  className={classes.link}
                />
              </Button>
            </div>
            {isMobileToolsOpen ? (
              <>
                <div className="mobile_blur" />
                <div className="mobile_tools">
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '32px',
                    }}
                  >
                    <div className="header">
                      <div className="search">
                        <input type="text" />
                        <SearchIcon className="search_icon" />
                      </div>
                      <span
                        onClick={() => {
                          setIsMobileToolsOpen(false);
                        }}
                      >
                        X
                      </span>
                    </div>
                    <div className="links">
                      {items.map(currentItem => (
                        <a
                          className={
                            currentItem.path === pathName ? 'select' : ''
                          }
                          href={currentItem.path}
                          key={currentItem.path}
                        >
                          {currentItem.name}
                        </a>
                      ))}
                    </div>
                  </div>
                  <div
                    className="button"
                    onClick={async () => {
                      await cleanAuth();
                      window.location.replace('/login');
                    }}
                  >
                    <img
                      src={BotaoLogout}
                      alt="link de saida da aplicação "
                      className={classes.link}
                    />
                  </div>{' '}
                </div>
              </>
            ) : (
              false
            )}

            <img
              onClick={() => {
                setIsMobileToolsOpen(true);
              }}
              className="hamburger"
              src={Hamburger}
              alt="hamburger"
            />
          </Toolbar>
        </AppBar>
      </div>
    </div>
  );
}
