import axios from 'axios';
import { baseURL } from './config';

const requester = async (method, endpoint, token = null, data = {}) => {
  const config = {
    method,
    baseURL,
    url: endpoint,
    data,
  };
  if (token) config.headers = { Authorization: `Bearer ${token}` };

  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.message);
    }
  );

  return axios
    .request(config)
    .then(response => [null, response])
    .catch(error => [error]);
};

export default requester;
