import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import axios from 'axios';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {
  Player,
  BigPlayButton,
  ControlBar,
  PlaybackRateMenuButton,
  VolumeMenuButton,
} from 'video-react';
import ReactPlayer from 'react-player';

import { baseURL } from '../../resources/config';
import { eventsHandler } from '../../utils/events';
import { language } from '../../languages';
import PrimaryButton from '../buttons/PrimaryButton.component';
import Playlist from '../playlist/Playlist';
import generateUUID from '../../utils/generateUUID';

const styles = {
  container: {
    flex: 1,
    justifyContent: 'space-between',
    marginBottom: 50,
  },
  item: {
    cursor: 'pointer',
    '&:hover': {
      background: '#bd9bda',
      borderRadius: 5,
    },
  },
  activeItem: {
    background: '#3BB877',
    borderRadius: 5,
  },
  icon: {
    width: 29,
    height: 29,
  },
  dialog: {
    minWidth: 400,
  },
  eventsContainer: {
    maxHeight: 200,
    overflow: 'auto',
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  list: {
    width: '100%',
  },
  checkbox: {
    marginRight: 5,
  },
  player: {
    width: '70%',
  },
  textField: {
    width: '100%',
  },
};

const VideoView = ({
  classes,
  matchId,
  token,
  events,
  handlerCompile,
  hidden,
  matchData,
}) => {
  const [eventKey, setEventKey] = useState(null);
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState('');
  const [url, setUrl] = useState('');
  const [eventsList, setEventsList] = useState([]);
  const [link, setLink] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const playerRef = useRef(null);

  useEffect(() => {
    const config = {
      method: 'GET',
      baseURL,
      url: `/analysis/match/${matchId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then(response => {
        setUrl(response.data.signedUrl);
        setLink(response.data.signedUrl);
        setIsLink(response.data.isLink);
      })
      .catch(error => {
        console.error(error);
      });
  }, [matchId, token]);

  const handleStateChange = state => {
    // Handle state change here
  };

  const seek = seconds => () => {
    playerRef.current.seek(seconds);
  };

  const handlerClick = key => {
    const event = events.find(e => e.key === key);
    setEventKey(key);

    if (link != null) {
      // Handle ReactPlayer seekTo
      // this.reactPlayer.seekTo(event.seconds);
      setPlaying(true);
    } else {
      playerRef.current.seek(event.seconds);
      playerRef.current.play();
    }
  };

  const getEventText = (time, team, type, extraInfo) => {
    if (type && type.code === 'end-match') {
      return `${time} - ${team} # ${type.code}`;
    }

    let eventType = type;
    if (type === 'card' && extraInfo && JSON.parse(extraInfo).card) {
      eventType = JSON.parse(extraInfo).card;
    }

    return `${time} - ${language.events[team]} # ${
      language.events[eventsHandler(eventType)]
    }`;
  };

  const filterEvents = e => {
    return (
      e.eventType.code !== 'ball-possession' &&
      e.eventType.code !== 'pass' &&
      e.eventType.code !== 'wrong-pass' &&
      e.eventType.code !== 'stopped-ball' &&
      e.eventType.code !== 'end-match'
    );
  };

  const getFilteredEvents = () => {
    return events.filter(filterEvents).map(({ time, team, eventType, key }) => {
      const name = getEventText(
        time,
        team,
        eventType.code,
        eventType.extraInfo
      );
      const singleTime = name.split('-')[0].trimEnd();
      const title = name
        .split('-')[1]
        .trimStart()
        .replace('Casa', matchData.home)
        .replace('Visitante', matchData.away);

      function timeStringToSeconds(timeString) {
        const timeArray = timeString.split(':');
        const hours = parseInt(timeArray[0], 10) || 0;
        const minutes = parseInt(timeArray[1], 10) || 0;
        const seconds = parseInt(timeArray[2], 10) || 0;

        return hours * 3600 + minutes * 60 + seconds;
      }

      return {
        time: timeStringToSeconds(singleTime),
        title,
        id: key,
      };
    });
  };

  const populateList = useMemo(() => getFilteredEvents(), []);

  const handleEventsSelect = useCallback(e => setEventsList(e), []);

  const isYoutubeVideo =
    typeof link === 'string'
      ? link.includes('youtube') || link.includes('youtu.be')
      : true;

  return (
    <div hidden={hidden}>
      <Grid container direction="row" justifyContent="center" spacing={3}>
        <Grid item xs={8}>
          {link ? (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <ReactPlayer
                  url={link}
                  ref={playerRef}
                  playing={playing}
                  controls={true}
                  config={{
                    youtube: {
                      playerVars: {
                        start: 0,
                        rel: 0,
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            <Player ref={playerRef} src={url}>
              <BigPlayButton position="center" />
              <ControlBar autoHide={false}>
                <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                <VolumeMenuButton vertical />
              </ControlBar>
            </Player>
          )}
          <Playlist
            isYoutubeVideo={isYoutubeVideo}
            onEventsSelect={handleEventsSelect}
            url={link}
            breaks={populateList}
          />
        </Grid>
        <Grid item xs={1}>
          {isLink ? (
            ''
          ) : !isYoutubeVideo ? (
            <PrimaryButton
              variant="outlined"
              color="primary"
              text="Gerar compilado"
              onClick={() => {
                setDescription('');
                setOpen(true);
              }}
            />
          ) : (
            false
          )}
        </Grid>
      </Grid>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <ValidatorForm
          onSubmit={() => {
            handlerCompile(description, eventsList);
            setOpen(false);
          }}
        >
          <DialogTitle id="alert-dialog-title">Compilado</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Adicione uma descrição para gerar o compilado.
            </DialogContentText>
            <TextValidator
              label={language.newMatch.description}
              className={classes.textField}
              validators={['required']}
              errorMessages={[language.newMatch.required]}
              value={description}
              onChange={event => setDescription(event.target.value)}
              margin="normal"
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary" autoFocus>
              Gerar
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(VideoView);
