import axios from 'axios';
import { baseURL } from './config';

const downloadFile = async (method, endpoint, token) => {
  const config = {
    method,
    baseURL,
    url: endpoint,
    responseType: 'blob',
    headers: {
      Accept: 'application/vnd.ms-excel',
      Authorization: `Bearer ${token}`,
    },
  };

  return axios
    .request(config)
    .then(response => [null, response.data])
    .catch(error => [error]);
};

export default downloadFile;
