import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import 'react-datepicker/dist/react-datepicker.css';
import { language } from '../../languages';
import './index.css';
import requester from '../../resources/requester';

const useStyles = makeStyles(theme => ({
  card: {
    justifyContent: 'center',
    height: '100vh',
    width: '55%',
  },

  title: {
    display: 'flex',
    paddingTop: 200,
    paddingLeft: 160,
    paddingBottom: 10,
    color: '#A1C6EA',
    fontSize: 30,
  },
  subtitle: {
    display: 'flex',
    paddingTop: 14,
    paddingLeft: 160,
    paddingBottom: 10,
    color: '#212121',
    fontSize: 14,
  },
  form: {
    height: '200px',
    alignItems: 'center',
    paddingTop: 10,
  },
  textField: {
    marginLeft: theme.spacing(20),
    width: 280,
    border: '10vh',
    paddingBottom: 20,
  },
  label: {
    marginLeft: theme.spacing(20),
    paddingTop: 8,
    paddingBottom: 8,
  },
  upload: {
    flex: 1,
    alignSelf: 'center',
    marginTop: 10,
    marginLeft: theme.spacing(1),
  },
  button: {
    marginTop: 2,
    paddingLeft: 8,
    marginLeft: theme.spacing(19),
  },
  buttonText: {
    backgroundColor: '#1B263B',
    width: 280,
    height: 40,
    textTransform: 'none',
    color: 'white',
    fontSize: 13,
  },
}));

const ForgotPasswordCard = () => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
        window.location.replace('/login');
      }, 3500);
    }
  }, [open]);

  const resetEmail = async () => {
    setOpen(true);
    const [error, response] = await requester(
      'POST',
      `/forgotPassword?email=${email}`,
      null
    );

    if (error) {
      console.error(error);
    }
  };

  const handleClose = () => setOpen(false);

  return (
    <Card className="forgot_password_card">
      <h1 className="title">{language.forgotPasswordScreen.title}</h1>
      <h4 className="subtitle">{language.forgotPasswordScreen.subtitle}</h4>
      <ValidatorForm className="form" onSubmit={() => setOpen(true)}>
        <div className="textField">
          <h4 className="label">Insira seu e-mail</h4>
          <TextValidator
            id="outlined-password"
            value={email}
            size="small"
            onChange={e => setEmail(e.target.value)}
            variant="outlined"
            type="email"
            validators={['required', 'maxStringLength:50']}
            errorMessages={[
              language.newMatch.required,
              language.newMatch.validation.maxLength50,
            ]}
          />
        </div>
        <button onClick={resetEmail} type="button">
          {language.forgotPasswordScreen.change}
        </button>
      </ValidatorForm>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {language.forgotPasswordScreen.dialog.title.replace('[email]', email)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {language.forgotPasswordScreen.dialog.subtitle}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default ForgotPasswordCard;
