import { createTheme } from '@material-ui/core/styles';
import colors from '../styles/abstracts/_variables.scss';

const { colorPrimary, colorPrimaryLight, colorPrimaryDark } = colors;

const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontSize: 16,
  },
  palette: {
    primary: {
      light: colorPrimaryLight,
      main: colorPrimary,
      dark: colorPrimaryDark,
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#00a152',
      main: '#00e676',
      dark: '#33eb91',
      contrastText: '#ffffff',
    },
    auxiliary: {
      one: '#1b263b',
      two: '#a1c6ea',
    },
    red: {
      main: '#f44336',
    },
    green: {
      main: '#1bc47d',
    },
    yellow: {
      main: '#ffeb3b',
    },
    gray: {
      light: '#fafafa',
      main: '#bdbdbd',
      dark: '#9e9e9e',
    },
  },
  shape: {
    borderRadius: 8,
  },
  overrides: {
    MuiDrawer: {
      paper: {
        background: '#6f4e8c',
        '& *': { color: '#fff' },
      },
    },
    MuiButton: {
      outlinedPrimary: {
        color: '#1bc47d',
        border: '1px solid #bdbdbd',
        '&:hover': {
          border: '1px solid #1bc47d',
          backgroundColor: 'white',
        },
      },
      outlinedSecondary: {
        color: '#a1c6ea',
        border: '1px solid #bdbdbd',
        '&:hover': {
          border: '1px solid #a1c6ea',
          backgroundColor: 'white',
        },
      },
      contained: {
        border: '1px solid #bdbdbd',
        boxShadow: 'none',
        backgroundColor: 'white',
        '&:hover': {
          border: '1px solid #1bc47d',
          backgroundColor: 'white',
          boxShadow: 'none',
        },
      },
      containedPrimary: {
        '&:hover': {
          border: '1px solid #6f4e8c',
        },
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: 18,
      },
    },
  },
});

export default theme;
