import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import InputMask from 'react-input-mask';
import ArrowRightIcon from '@material-ui/icons/ArrowRightAlt';

import useGlobal from '../../store';
import { language } from '../../languages';

import ball from '../../assets/images/ball.png';
import soccerField from '../../assets/images/soccer-field.png';
import soccerFieldHalf from '../../assets/images/soccer-field-half.png';
import whistle from '../../assets/images/whistle.png';
import penalty from '../../assets/images/penalty.png';
import offside from '../../assets/images/offside.png';
import cards from '../../assets/images/cards.png';
import transition from '../../assets/images/transition.png';
import kickOff from '../../assets/images/kick-off.png';
import shot from '../../assets/images/shot.png';
import { error } from '../../utils/toast';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.gray.dark,
  },

  iconeClose: {
    color: '#1B263B',
    width: '33px',
    height: '33px',
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h3">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon className={classes.iconeClose} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    padding: theme.spacing(1),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles(theme => ({
  canvas: {
    position: 'fixed',
  },
  attackDirection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 5,
  },
  attackDirectionText: {
    fontSize: 16,
  },
  attackDirectionIcon: {
    fontSize: 30,
  },
  soccerField: {
    width: 550,
    height: 400,
    margin: 'auto',
  },
  imageEventWrapper: {
    display: 'grid',
    justifyContent: 'center',
    justifyItems: 'center',
    margin: 30,
  },
  imageEvent: {
    width: '50%',
  },
  importantName: {
    width: '50%',
  },
  // vars: {
  //   display: 'flex',
  //   justifyContent: 'space-between',
  // },

  containerBotaoSalvarEvento: {
    position: 'absolute',
    top: '4px; right: 100px',
    color: '#fff',
  },
  botaosalvarEvento: {
    borderRadius: '12px',
    background: 'var(--1-b-263-b, #1B263B)',
    color: '#FFF',
    padding: 10,
    '&:hover': {
      backgroundColor: '#1B263B',
    },
  },

  containerRadioGroup: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, auto)',
    justifyContent: 'start',
    padding: 8,
    position: 'relative',
  },

  radioGroup: {
    display: 'none',
  },

  botaoRadioGroup: {
    borderRadius: '20px',
    '&:hover': {
      opacity: '0.5',
    },
    border: '1px solid #1B263B',
    background: '#1B263B',
    color: '#FFF',
    padding: '4px 25px',
  },
  form: {
    display: 'grid',
    // placeItems: 'end',

    // //     // flexDirection:'column-reverse',
    // gridTemplateColumns: 'repeat(4, auto)',
    // //     // flexWrap: 'wrap',
    // justifyContent: 'end',
  },

  vars: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, auto)',
    gap: 5,
  },

  textFieldNumeroDoJogador: {
    display: 'grid',
    // justifyItems: 'center',
    // alignContent: 'center',
  },

  importantName: {
    display: 'grid',
    alignContent: 'end',
  },

  textField: {
    display: 'grid',
    justifyContent: 'center',
    placeItems: 'center',
  },
}));

const EventDialog = () => {
  const classes = useStyles();
  const [globalState, globalActions] = useGlobal();
  const { open, event: dialogEvent } = globalState.eventDialog;
  const {
    event,
    eventName,
    team,
    time,
    uuid,
    duration,
    callback,
  } = dialogEvent;
  const [localCoordState, localCoordsSetState] = useState(null);
  const [localShotState, localShotSetState] = useState(null);
  const [localCardState, localCardSetState] = useState(null);
  const [localKickOffState, localKickOffSetState] = useState(null);
  const [localTransitionState, localTransitionSetState] = useState(null);
  const [localPlayerState, localPlayerSetState] = useState(null);
  const [localImportantState, localImportantSetState] = useState(null);
  const [localVar1State, localVar1SetState] = useState(null);
  const [localVar2State, localVar2SetState] = useState(null);
  const [localVar3State, localVar3SetState] = useState(null);

  const clearState = () => {
    localShotSetState(null);
    localCardSetState(null);
    localKickOffSetState(null);
    localTransitionSetState(null);
    localPlayerSetState(null);
    localImportantSetState(null);
    localVar1SetState(null);
    localVar2SetState(null);
    localVar3SetState(null);
  };

  const soccerFieldEvents = ['shot', 'important'];
  const soccerFieldEventsHalfType = ['shot'];

  const handleSoccerField = soccerFieldEvents.includes(event);
  const handleSoccerFieldType = soccerFieldEventsHalfType.includes(event)
    ? 'half'
    : 'complete';

  const handleImageEvent = () => {
    switch (event) {
      case 'kickOff':
        return kickOff;
      case 'transition':
        return transition;
      case 'card':
        return cards;
      case 'foul':
        return whistle;
      case 'penaltyKick':
        return penalty;
      case 'offside':
        return offside;
      case 'shot':
        return shot;
      default:
        return null;
    }
  };

  const canvas = React.createRef();
  const handleClick = e => {
    const canvasRef = canvas.current;
    const rect = canvasRef.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const ctx = canvasRef.getContext('2d');
    ctx.clearRect(0, 0, canvasRef.width, canvasRef.height);
    const drawing = new Image();
    drawing.src = ball;
    drawing.onload = function() {
      ctx.drawImage(drawing, x, y, 20, 20);
    };
    ctx.fill();

    localCoordsSetState({ x, y });
  };

  const handleShotChange = e => localShotSetState(e.target.value);
  const handleCardChange = e => localCardSetState(e.target.value);
  const handleKickOffChange = e => localKickOffSetState(e.target.value);
  const handleTransitionChange = e => localTransitionSetState(e.target.value);
  const handlePlayerChange = e => localPlayerSetState(e.target.value);
  const handleImportantChange = e => localImportantSetState(e.target.value);
  const handleVar1Change = e => localVar1SetState(e.target.value);
  const handleVar2Change = e => localVar2SetState(e.target.value);
  const handleVar3Change = e => localVar3SetState(e.target.value);

  const handleShot = () => (
    <RadioGroup
      value={localShotState}
      onChange={handleShotChange}
      row
      className={classes.containerRadioGroup}
    >
      <FormControlLabel
        control={<Radio className={classes.radioGroup} />}
        value="goal"
        className={classes.botaoRadioGroup}
        label={language.events.goal}
      />
      <FormControlLabel
        control={<Radio className={classes.radioGroup} />}
        value="shot-on-target"
        className={classes.botaoRadioGroup}
        label={language.events.shotOnTarget}
      />
      <FormControlLabel
        control={<Radio className={classes.radioGroup} />}
        value="shot-off-target"
        className={classes.botaoRadioGroup}
        label={language.events.shotOffTarget}
      />
      <FormControlLabel
        control={<Radio className={classes.radioGroup} />}
        value="shot-on-post"
        className={classes.botaoRadioGroup}
        label={language.events.shotOnPost}
      />
    </RadioGroup>
  );

  const handleCard = () => (
    <RadioGroup
      value={localCardState}
      onChange={handleCardChange}
      row
      className={classes.containerRadioGroup}
    >
      <FormControlLabel
        control={<Radio className={classes.radioGroup} />}
        value="yellow-card"
        className={classes.botaoRadioGroup}
        label={language.events.yellowCard}
      />
      <FormControlLabel
        control={<Radio className={classes.radioGroup} />}
        value="red-card"
        className={classes.botaoRadioGroup}
        label={language.events.redCard}
      />
    </RadioGroup>
  );

  const handleKickOff = () => (
    <RadioGroup
      value={localKickOffState}
      onChange={handleKickOffChange}
      className={classes.containerRadioGroup}
      row
    >
      <FormControlLabel
        control={<Radio className={classes.radioGroup} />}
        value="kick-off-left"
        className={classes.botaoRadioGroup}
        label={language.events.left}
      />
      <FormControlLabel
        control={<Radio className={classes.radioGroup} />}
        value="kick-off-center"
        className={classes.botaoRadioGroup}
        label={language.events.center}
      />
      <FormControlLabel
        control={<Radio className={classes.radioGroup} />}
        value="kick-off-right"
        className={classes.botaoRadioGroup}
        label={language.events.right}
      />
    </RadioGroup>
  );

  const handleTransition = () => (
    <RadioGroup
      value={localTransitionState}
      onChange={handleTransitionChange}
      row
      className={classes.containerRadioGroup}
    >
      <FormControlLabel
        control={<Radio className={classes.radioGroup} />}
        value="offensive-transition"
        className={classes.botaoRadioGroup}
        label={language.events.offensiveTransition}
      />
      <FormControlLabel
        control={<Radio className={classes.radioGroup} />}
        value="defensive-transition"
        className={classes.botaoRadioGroup}
        label={language.events.defensiveTransition}
      />
    </RadioGroup>
  );

  const handlePlayer = () => (
    <ValidatorForm className={classes.form} onSubmit={null}>
      <InputMask
        mask="99"
        maskChar={null}
        value={localPlayerState}
        onChange={handlePlayerChange}
      >
        {() => (
          <TextValidator
            id="outlined-player"
            placeholder="Número do jogador"
            size="small"
            className={classes.textFieldNumeroDoJogador}
            margin="normal"
            variant="outlined"
            validators={['required', 'matchRegexp:^[0-9]{1,2}$']}
            errorMessages={[
              language.eventDialog.required,
              language.eventDialog.validation.player,
            ]}
          />
        )}
      </InputMask>
    </ValidatorForm>
  );

  const handleImportant = () => (
    <ValidatorForm className={classes.form} onSubmit={null}>
      <TextValidator
        id="outlined-important"
        placeholder="Nome do lance"
        size="small"
        className={classes.importantName}
        value={localImportantState}
        onChange={handleImportantChange}
        margin="normal"
        variant="outlined"
      />
      <div className={classes.vars}>
        <TextValidator
          id="outlined-important"
          placeholder="Descrição #1"
          className={classes.textField}
          size="small"
          value={localVar1State}
          onChange={handleVar1Change}
          margin="normal"
          variant="outlined"
        />
        <TextValidator
          id="outlined-important"
          placeholder="Descrição #2"
          className={classes.textField}
          size="small"
          value={localVar2State}
          onChange={handleVar2Change}
          margin="normal"
          variant="outlined"
        />
        <TextValidator
          id="outlined-important"
          placeholder="Descrição #3"
          size="small"
          className={classes.textField}
          value={localVar3State}
          onChange={handleVar3Change}
          margin="normal"
          variant="outlined"
        />
      </div>
    </ValidatorForm>
  );

  const handleDialogClose = () => {
    globalActions.setEventDialog(false, []);
    clearState();
  };

  return (
    <Dialog
      onClose={() => globalActions.setEventDialog(false, [])}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleDialogClose}>
        {language.events[event]}
      </DialogTitle>

      <DialogContent>
        {handleSoccerField ? (
          <>
            {handleSoccerFieldType === 'complete' ? (
              <div className={classes.attackDirection}>
                <span className={classes.attackDirectionText}>
                  {language.eventDialog.attackDirection}
                </span>
                <ArrowRightIcon className={classes.attackDirectionIcon} />
              </div>
            ) : null}
            <canvas
              ref={canvas}
              width={550}
              height={400}
              className={classes.canvas}
              onClick={handleClick}
            />
            <img
              id="imgCanvas"
              alt="soccer-field"
              className={classes.soccerField}
              src={
                handleSoccerFieldType === 'complete'
                  ? soccerField
                  : soccerFieldHalf
              }
            />
          </>
        ) : (
          <div className={classes.imageEventWrapper}>
            <img
              id="imgEvent"
              alt="event"
              className={classes.imageEvent}
              src={handleImageEvent()}
            />
          </div>
        )}
        {event === 'shot' && handleShot()}
        {(event === 'foul' || event === 'card') && handleCard()}
        {event === 'kickOff' && handleKickOff()}
        {event === 'transition' && handleTransition()}
        {event === 'important' && handleImportant()}
        {event !== 'transition' && event !== 'kickOff' && handlePlayer()}
      </DialogContent>
      <DialogActions className={classes.containerBotaoSalvarEvento}>
        <Button
          className={classes.botaosalvarEvento}
          disabled={
            event === 'important' &&
            (localImportantState === null || localImportantState === '')
          }
          onClick={() => {
            const name =
              localShotState ||
              localKickOffState ||
              localTransitionState ||
              eventName;

            if (localShotState === 'goal') {
              globalActions.createEvent(
                'shot',
                team,
                localPlayerState === '' ? null : localPlayerState,
                time,
                uuid,
                {
                  coords: { ...localCoordState },
                }
              );
            }

            globalActions.createEvent(
              name,
              team,
              localPlayerState === '' ? null : localPlayerState,
              time,
              uuid,
              localCoordState || localCardState || localImportantState
                ? {
                    coords: { ...localCoordState },
                    ...(localCardState && { card: localCardState }),
                    ...(localImportantState && {
                      name: localImportantState,
                      duration: duration.toString(),
                      vars: {
                        var1: localVar1State,
                        var2: localVar2State,
                        var3: localVar3State,
                      },
                    }),
                  }
                : null
            );

            if (event === 'foul' && localCardState !== null) {
              globalActions.createEvent(
                localCardState,
                team,
                localPlayerState === '' ? null : localPlayerState,
                time,
                uuid,
                null
              );
            }

            if (
              !localPlayerState &&
              event !== 'transition' &&
              event !== 'kickOff'
            ) {
              error('Necessário ter o número do jogador');
              return;
            }

            if (callback && typeof callback === 'function') {
              handleDialogClose();

              callback();
            }
          }}
        >
          {language.eventDialog.saveEvent}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventDialog;
