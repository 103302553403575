import React from 'react';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PrimaryButton from '../components/buttons/PrimaryButton.component';
import SignIn from '../components/SignIn';

const useStyles = makeStyles(theme => ({
  section: {
    backgroundColor: '#A1C6EA',
    padding: '0',
    height: '100%',
  },
}));

const Cadastro = () => {
  const classes = useStyles();

  return (
    <section className={classes.section}>
      <SignIn />
    </section>
  );
};

export default Cadastro;
