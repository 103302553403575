const teamConfigurations = {
  general: {
    id: null,
    name: '',
    abbreviation: '',
    description: '',
    logo: '',
  },
  players: [],
};

export default teamConfigurations;
