const eventDialog = {
  open: false,
  event: {
    event: null,
    eventName: null,
    team: null,
    time: null,
    uuid: null,
  },
};

export default eventDialog;
