import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Loader from 'react-loader-spinner';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import CloudDownload from '@material-ui/icons/CloudDownload';

import useGlobal from '../store';
import { language } from '../languages';

import PrimaryButton from '../components/buttons/PrimaryButton.component';
import AnalysisView from '../components/views/AnalysisView.component';
import VideoView from '../components/views/VideoView.component';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: '14vh 0 2vh 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  line: {
    width: '100%',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '2.5vh 0',
    color: '#292a2d',
  },
  circle: {
    background: '#a1c6ea',
    borderRadius: '100%',
    width: '60px',
    height: '60px',
  },
  scoreView: {
    border: '1px solid #cccccc',
    color: '#7e7e7e',
    borderRadius: '8px',
    padding: '4px 18px',
    width: 'fit-content',
    fontWeight: 'black',
  },
  title: {
    fontSize: '18px',
    color: 'black',
    fontWeight: 500,
    marginBottom: 5,
    textAlign: 'right',
  },
  description: {
    fontSize: '14px',
    color: 'black',
    textAlign: 'right',
  },
  score: {
    fontSize: 30,
    color: 'black',
    fontWeight: 500,
    textAlign: 'left',
  },
  downloadReport: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  containerHeader: {
    flex: 1,
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  header: {
    background: 'white',
    marginBottom: 30,
  },
  button: {
    background: '#a1c6ea',
    color: '#000000',
    height: 'fit-content',
    padding: '8px 12px',
    border: '1px solid #a1c6ea',
  },
}));

const Analysis = ({ matchId }) => {
  const [globalState, globalActions] = useGlobal();
  const { selected: match, options: matches } = globalState.matches;
  const { options: analysis, events } = globalState.analysis;
  const {
    fetchAnalysis,
    fetchEvents,
    fetchMatches,
    resetAnalysisState,
    downloadReport,
  } = globalActions;
  const [localState, localSetState] = useState({
    loaded: false,
    analysisToShow: {},
  });
  const { loaded, analysisToShow } = localState;
  const [value, setValue] = useState(0);
  const handleChange = (_, newValue) => setValue(newValue);
  const [matchData, setMatchData] = useState({});

  const selectMatch = async () => {
    const matchesFetched = await fetchMatches();
    const selectedMatch = matchesFetched.find(
      ({ id }) => id.toString() === matchId
    );
    setMatchData(selectedMatch);
    if (match) globalActions.updateMatches('selected', selectedMatch);
  };

  useEffect(() => {
    selectMatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    resetAnalysisState();
    fetchAnalysis(matchId);
    fetchEvents(matchId);
    if (matches && matches.length === 0) selectMatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ('home' in analysis && 'away' in analysis) {
      localSetState({ ...localState, loaded: true, analysisToShow: analysis });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysis]);

  const classes = useStyles();

  const handlerCompile = async (description, eventsKeys) => {
    await Promise.all(
      eventsKeys.map(eventKey => {
        globalActions.generateCompile(matchId, description, [eventKey]);
      })
    );
  };

  const onDownloadReport = async () => {
    localSetState({ ...localState, loaded: false });
    const reportFile = await downloadReport(matchId);
    const url = window.URL.createObjectURL(reportFile);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `Relatório - ${match.title} - ${match.home} ${match.homeScore} X ${
        match.awayScore
      } ${match.away}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    localSetState({ ...localState, loaded: true });
  };

  const MemoVideoView = memo(VideoView);

  return (
    <section>
      {loaded ? (
        <>
          <div className={classes.root}>
            <h3
              // eslint-disable-next-line react-native/no-inline-styles
              style={{
                width: 'fit-content',
                borderBottom: '6px solid #cadff3',
                borderRadius: '2px',
                padding: '12px 32px',
                color: '#273145',
                display: 'flex',
              }}
            >
              {language.analysis.title}
            </h3>
            <PrimaryButton
              variant="outlined"
              color="primary"
              icon={<CloudDownload />}
              text={language.analysis.downloadReport}
              className={classes.button}
              onClick={onDownloadReport}
            />
          </div>
          <div className={classes.analysis}>
            <Grid className={classes.containerHeader} container spacing={3}>
              <Grid item xs={5}>
                <div className={classes.score} />
              </Grid>
              <Grid item xs={3}>
                <div className={classes.description}>{match.description}</div>
              </Grid>
            </Grid>
          </div>
          <div className={classes.line}>
            <div
              // eslint-disable-next-line react-native/no-inline-styles
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <div className={classes.circle} />

              <span className={classes.scoreView}>
                {matchData.home || 'DDD'}
              </span>
              <span className={classes.scoreView}>
                {matchData.homeScore} x {matchData.awayScore}
              </span>
              <span className={classes.scoreView}>
                {matchData.away || 'GGG'}
              </span>

              <div className={classes.circle} />
            </div>

            <span>{`${new Date(match.date).getDate()}/${new Date(
              match.date
            ).getMonth() + 1}/${new Date(match.date).getFullYear()}`}</span>
          </div>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            variant="fullWidth"
            className={classes.header}
          >
            <Tab label="Análise" />
            <Tab label="Vídeos" />
          </Tabs>
          <AnalysisView analysisToShow={analysisToShow} hidden={value !== 0} />
          <MemoVideoView
            hidden={value !== 1}
            matchId={matchId}
            events={events}
            handlerCompile={handlerCompile}
            token={globalState.auth.token}
            matchData={matchData}
          />
        </>
      ) : (
        <Loader
          className="loader"
          type="BallTriangle"
          color="#A1C6EA"
          height={80}
          width={80}
        />
      )}
    </section>
  );
};

export default Analysis;
