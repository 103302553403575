import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'react-datepicker/dist/react-datepicker.css';
import ForgotPasswordCard from '../components/ForgotPasswordCard';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#A1C6EA',
    paddingLeft: 0,
    height: '100dvh',
    width: '100dvw',
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <ForgotPasswordCard />
    </section>
  );
};

export default ForgotPassword;
