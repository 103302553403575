import * as languages from './languages';
import configurations from '../states/configurations.state';

export function getLanguage() {
  try {
    return languages[configurations.language];
  } catch (error) {
    console.error(error);
  }
}

export const language = getLanguage();
