export const setEventDialog = (store, open, event) => {
  const eventDialog = {
    ...store.state.eventDialog,
    open,
    event,
  };

  store.setState({ ...store.state, eventDialog });
};

export default null;
