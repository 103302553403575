import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { language } from '../../languages';

const FormDialog = ({
  title,
  agreeOnClick,
  disagreeOnClick,
  open,
  children,
  contentClassName,
}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={contentClassName}> {children} </DialogContent>
      <DialogActions>
        <Button onClick={disagreeOnClick} color="primary">
          {language.commons.cancel}
        </Button>
        <Button onClick={agreeOnClick} color="primary" autoFocus>
          {language.commons.salvar}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormDialog;
