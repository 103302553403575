import requester from '../resources/requester';

export const setCompiledVideos = (store, videos) => {
  const compiledVideos = {
    ...store.state.compiledVideo,
    videos,
  };
  store.setState({ ...store.state, compiledVideos });
};

export const generateCompile = async (
  store,
  matchId,
  description,
  eventKeys
) => {
  const [error, response] = await requester(
    'POST',
    '/video-compile',
    store.state.auth.token,
    {
      matchId,
      description,
      eventKeys,
    }
  );
  if (error) {
    console.error(`Error: ${error}`);
  } else {
    console.log(response);
  }
};

export const getCompiledVideos = async store => {
  const [error, response] = await requester(
    'GET',
    '/video-compile',
    store.state.auth.token
  );
  if (error) {
    console.error(`Error: ${error}`);
  } else {
    setCompiledVideos(store, response.data);
  }
};

export const getSignedUrl = (store, id) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async resolve => {
    const [error, response] = await requester(
      'GET',
      `/video-compile/signedUrl/${id}`,
      store.state.auth.token
    );
    if (error) {
      console.error(`Error: ${error}`);
      resolve('');
    } else {
      resolve(response.data);
    }
  });
};

export const deleteVideoCompile = (store, id) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async resolve => {
    const [error] = await requester(
      'DELETE',
      `/video-compile/${id}`,
      store.state.auth.token
    );
    if (error) {
      console.error(`Error: ${error}`);
      resolve({ status: 500, message: 'Erro ao deletar o compilado' });
    } else {
      resolve({ status: 200, message: 'Sucesso ao deletar o compilado' });
    }
  });
};
