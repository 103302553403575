export const toggleDrawer = (store, open) => event => {
  if (
    event.type === 'keydown' &&
    (event.key === 'Tab' || event.key === 'Shift')
  ) {
    return;
  }

  const configurations = {
    ...store.state.configurations,
    drawer: open,
  };

  store.setState({ ...store.state, configurations });
};

export default null;
