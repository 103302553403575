import React, { useState, useEffect } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';

import 'react-datepicker/dist/react-datepicker.css';

import useGlobal from '../../store';
import { language } from '../../languages';
import { success, error } from '../../utils/toast';
import './index.css';
import requester from '../../resources/requester';

const ChangePasswordCard = ({ email, userToken }) => {
  const [, globalActions] = useGlobal();
  const [localState, setLocalState] = useState({
    password: '',
    repeatPassword: '',
  });

  const [open, setOpen] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (name, event) => {
    setLocalState({ ...localState, [name]: event.target.value });
  };

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', value => {
      if (value !== localState.password) return false;
      return true;
    });
  }, [localState.password]);

  const handleClose = () => setOpen(false);
  const handleAgree = async () => {
    const verifyUserToken = token => {
      return token;
    };

    if (userToken) {
      if (!verifyUserToken(userToken)) {
        error('Token inválido');
        setTimeout(() => {
          window.location.replace('/login');
        }, 1500);
        return;
      }
    }

    handleClose();
    const data = {
      email,
      password: localState.password,
    };

    if (userToken !== null) {
      const [error_, response] = await requester(
        'POST',
        `/reset/${userToken}`,
        null,
        data
      );
      if (error_) {
        setErrorMessage(response.message);
        console.error(error_);
      } else {
        success(response.message);
        console.log(response.data);
        setPasswordChanged(true);
        setTimeout(() => {
          window.history.replaceState(null, '', '/login');
          window.location.replace('/login');
        }, 3000);
      }
    } else {
      const response = await globalActions.changePassword(localState);
      if (response.status === 500) {
        error(response.message);
      } else {
        success(response.message);
      }
    }
  };

  return (
    <Card className="change_password_card">
      <h1 className="title">{language.changePasswordScreen.title}</h1>
      <ValidatorForm className="form" onSubmit={() => setOpen(true)}>
        <div className="input">
          <h4 className="label">Nova senha</h4>
          <TextValidator
            id="outlined-password"
            className="textField"
            value={localState.password}
            size="small"
            onChange={e => handleChange('password', e)}
            variant="outlined"
            type="password"
            validators={['required', 'maxStringLength:50']}
            errorMessages={[
              language.newMatch.required,
              language.newMatch.validation.maxLength50,
            ]}
          />
        </div>
        <div className="input">
          <h4 className="label">Confirme nova senha</h4>
          <TextValidator
            id="outlined-repeatPassword"
            className="textField"
            value={localState.repeatPassword}
            size="small"
            onChange={e => handleChange('repeatPassword', e)}
            variant="outlined"
            type="password"
            validators={['required', 'maxStringLength:50', 'isPasswordMatch']}
            errorMessages={[
              language.newMatch.required,
              language.newMatch.validation.maxLength50,
              language.changePasswordScreen.validation.wrongPasswords,
            ]}
          />
        </div>
        <button type="submit">{language.changePasswordScreen.change}</button>
      </ValidatorForm>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {language.changePasswordScreen.dialog.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {language.changePasswordScreen.dialog.subtitle}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {language.changePasswordScreen.dialog.cancel}
          </Button>
          <Button onClick={handleAgree} color="primary">
            {language.changePasswordScreen.dialog.agree}
          </Button>
        </DialogActions>
      </Dialog>
      {passwordChanged ? (
        <p>
          A senha foi alterada com sucesso. Por favor, faça login novamente.
        </p>
      ) : errorMessage ? (
        <p>{errorMessage}</p>
      ) : null}
    </Card>
  );
};

export default ChangePasswordCard;
