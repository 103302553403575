const common = {
  newMatchAnalysis: 'Nova análise',
  left: 'Esquerda',
  center: 'Centro',
  right: 'Direita',
  delete: 'Deletar',
  save: 'Salvar',
  download: 'Baixar',
  search: 'Pesquisar',
  cancel: 'Cancelar',
  confirm: 'Confirmar',
};

export const ptBR = {
  commons: {
    cancel: 'Cancelar',
    salvar: 'Salvar',
    name: 'Nome',
    email: 'E-mail',
  },
  matchCard: {
    dialog: {
      title: 'Tem certeza que deseja deletar a partida?',
      description:
        'Após deletar a partida não será mais possível recuperar a partida.',
      agreeText: 'Deletar',
      disagreeText: 'Cancelar',
    },
  },
  navBar: {
    myProfile: 'Meu Perfil',
    changePassword: 'Trocar Senha',
  },
  changePasswordScreen: {
    title: 'Trocar senha',
    oldPassword: 'Senha antiga',
    newPassword: 'Nova senha',
    confirmNewPassword: 'Confirme nova senha',
    change: 'Alterar',
    validation: {
      wrongPasswords: 'As senhas não conferem',
    },
    dialog: {
      title: 'Tem certeza que deseja trocar de senha?',
      subtitle: 'Após trocar a senha não será mais possível desfazer a ação.',
      agree: 'Aceitar',
      cancel: 'Cancelar',
    },
  },
  forgotPasswordScreen: {
    title: 'Esqueci minha senha',
    subtitle:
      'Coloque seu endereço de e-mail para que possamos te enviar o link de alteraração de senha',
    change: 'Redefinir senha',
    dialog: {
      title: 'Um e-mail foi enviado para [email] para a redefinição de senha',
      subtitle: 'Por favor, verifique sua caixa de entrada ou de spam',
    },
  },
  alertDialog: {
    generic: {
      title: 'Oops!',
      description: 'Algo deu errado. Tente novamente!',
    },
    login: {
      title: 'Erro ao logar!',
      description:
        'Não foi possível validar seu login/senha.\nVerifique seus dados e tente novamente!',
    },
    ballPossessionNotSelected: {
      title: 'Erro no evento!',
      description:
        'Ocorreu um erro ao salvar o evento!\nTodo evento deve possuir uma posse de bola associada.\nVerifique se o botão de posse está selecionado!',
    },
    videoUploadNotFinished: {
      title: 'Atenção!',
      description:
        'Não é possível completar uma análise enquanto o upload do vídeo não for finalizado!\nEspere um pouco e tente novamente!',
    },
    agree: 'Entendi',
  },
  transferDialog: {
    title: 'Upload de vídeo',
    description: '',
  },
  login: {
    required: 'Obrigatório',
    dontHaveAnAccount: 'Not a member?',
    clickHereToRegister: 'Sing up now',
  },
  drawer: {
    matches: 'Partidas',
    newMatchAnalysis: common.newMatchAnalysis,
    compiled: 'Compilados',
    configurations: 'Configurações',
    users: 'Usuários',
  },
  matches: {
    newMatchAnalysis: common.newMatchAnalysis,
    noMatchToShow: 'Não há partidas a serem mostradas...',
    searchFildPlaceholder: 'Pesquise sua partida...',
    dialogTitle: 'Deseja criar uma nova partida?',
    dialogDescription:
      'Você não possui nenhuma partida criada. Crie uma agora mesmo.',
    dialogAgreeText: 'Criar',
    dialogDesagreeText: 'Cancelar',
  },
  newMatch: {
    newMatchAnalysis: common.newMatchAnalysis,
    required: 'Obrigatório',
    validation: {
      maxLength50: 'O tamanho máximo desse campo é de 50 caracteres',
      teamAcronym: 'Ex: ABC',
      score: 'Ex: 0 x 0',
    },
    title: 'Título',
    date: 'Data',
    home: 'Mandante',
    score: 'Placar',
    away: 'Visitante',
    video: 'Vídeo da partida (em breve)',
    buttonVideo: 'Selecione seu vídeo',
    description: 'Descrição',
    createMatchAnalysis: 'Criar análise de partida',
    file: 'Vídeo',
    dialogTitle: 'Criando a partida e enviando seu vídeo...',
  },
  events: {
    away: 'Visitante',
    home: 'Casa',
    registerEvents: 'Registro de eventos',
    shot: 'Chute',
    goal: 'Gol',
    shotOnTarget: 'Chute no gol',
    shotOffTarget: 'Chute para fora',
    shotOnPost: 'Chute na trave',
    kickOff: 'Saída de bola',
    kickOffRight: 'Saída de bola pela direita',
    kickOffLeft: 'Saída de bola pela direita',
    left: common.left,
    center: common.center,
    right: common.right,
    foul: 'Falta',
    penaltyKick: 'Pênalti',
    offside: 'Impedimento',
    cornerKick: 'Escanteio',
    pass: 'Passe',
    wrongPass: 'Passe errado',
    longPass: 'Lançamento',
    cross: 'Cruzamento',
    stoppedBall: 'Bola parada',
    pause: 'Pausa',
    tackle: 'Roubada de bola',
    transition: 'Transição',
    offensiveTransition: 'Transição ofensiva',
    defensiveTransition: 'Transição defensiva',
    ballPossession: 'Posse de bola',
    ballPossessionHome: 'Posse de bola casa',
    ballPossessionAway: 'Posse de bola casa',
    goalkeeper: 'Goleiro',
    important: 'Importante',
    startEnd: 'Início/Fim',
    card: 'Cartão',
    yellowCard: 'Cartão amarelo',
    redCard: 'Cartão vermelho',
    completeAnalysis: 'Finalizar Análise',
    eventListTitle: 'Lista de Eventos',
  },
  eventDialog: {
    soccerField: 'Campo de futebol',
    attackDirection: 'Sentido do ataque',
    saveEvent: 'Salvar evento',
    player: 'Número do jogador',
    required: 'Obrigatório',
    validation: {
      player: 'Ex: 10',
    },
    importantName: 'Nome do lance',
    var: 'Variável',
  },
  analysis: {
    assists: 'Assistências',
    ballPossession: 'Posse de bola',
    cornersKick: 'Escanteios',
    downloadReport: 'Baixar Relatório',
    fouls: 'Faltas',
    goals: 'Gols',
    matchAnalysis: 'Análise da partida',
    offsides: 'Impedimentos',
    passes: 'Passes',
    passesPrecision: 'Precisão dos passes',
    shots: 'Chutes',
    shotsOnTarget: 'Chutes no gol',
    shotsOffTarget: 'Chutes para fora',
    title: 'Partida',
    unavailableData: 'Dados indisponíveis',
    wrongPasses: 'Passes errados',
  },
  compiled: {
    title: 'Compilado de Vídeos',
    save: common.save,
    delete: common.delete,
    download: common.download,
    actions: 'Ações',
    cancel: common.cancel,
    confirm: common.confirm,
    pagination: {
      labelDisplayedRows: '{from} - {to} de {count}',
      labelRowsSelect: 'linhas',
      labelRowsPerPage: 'Compilados por página',
      firstAriaLabel: 'Primeira página',
      firstTooltip: 'Primeira página',
      previousAriaLabel: 'Página Anterior',
      previousTooltip: 'Página Anterior',
      nextAriaLabel: 'Proxima Página',
      nextTooltip: 'Proxima Página',
      lastAriaLabel: 'Última Página',
      lastTooltip: 'Última Página',
    },
    body: {
      emptyDataSourceMessage: 'Nenhum compilado para mostrar',
      filterRow: {
        filterTooltip: 'Filtrar',
      },
    },
    toolbar: {
      searchTooltip: common.search,
      searchPlaceholder: common.search,
    },
    tableTitle: {
      compile: {
        status: 'Estado',
        createdAt: 'Data da Criação',
        description: 'Descrição do Compilado',
        createdBy: 'Criado por',
      },
      match: {
        title: 'Título do Jogo',
        result: 'Resultado do Jogo',
        date: 'Data do Jogo',
        description: 'Descrição do Jogo',
      },
    },
    status: {
      CREATED: 'Criado',
      ERROR: 'Erro',
      IN_PROGRESS: 'Em progresso',
      DONE: 'Finalizado',
    },
  },
  configurations: {
    title: 'Cad. de times',
    team: 'Nome do time',
    acronym: 'Sigla',
    save: 'Salvar',
    formTitle: 'Novo Jogador',
    information: 'Informações',
    players: 'Jogadores',
  },
  users: {
    title: 'Usuários',
    newUser: 'Novo Usuário',
    table: {
      title: {
        email: 'E-mail',
        name: 'Nome',
        lastAccess: 'Último Acesso',
      },
      pagination: {
        labelRowsPerPage: 'Usuários por página',
      },
      body: {
        emptyDataSourceMessage: 'Nenhum dado para mostrar',
      },
    },
  },
  userDialog: {
    title: 'Cadastrar novo usuário',
    name: 'Nome',
    email: 'E-mail',
    save: 'Salvar',
    validation: {
      isEmail: 'Não é um e-mail válido.',
    },
  },
  toast: {
    videoUpload: 'O upload do vídeo foi finalizado!',
  },
};

export default null;
