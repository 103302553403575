import requester from '../resources/requester';
import downloadFile from '../resources/downloadFile';

export const setMatches = (store, matchesOptions) => {
  const matches = {
    ...store.state.matches,
    options: matchesOptions,
  };
  store.setState({ ...store.state, matches });
};

export const addMatch = (store, match) => {
  const matches = {
    ...store.state.matches,
    options: [...store.state.matches.options, match],
  };
  store.setState({ ...store.state, matches });
};

export const removeMatch = (store, id) => {
  const filteredOptions = store.state.matches.options.filter(m => m.id !== id);
  const matches = {
    ...store.state.matches,
    options: [...filteredOptions],
  };
  store.setState({ ...store.state, matches });
};

export const updateMatches = (store, property, value) => {
  try {
    const matches = {
      ...store.state.matches,
      [property]: value,
    };
    store.setState({ ...store.state, matches });
  } catch (error) {
    console.error(error);
  }
};

export const fetchMatches = store => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const [error, response] = await requester(
      'GET',
      '/matches',
      store.state.auth.token
    );
    if (error) {
      console.error(JSON.stringify(error));
      reject(error);
    } else {
      setMatches(store, response.data);
      resolve(response.data);
    }
  });
};

export const createMatch = (store, data) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const [error, response] = await requester(
        'POST',
        '/matches',
        store.state.auth.token,
        data
      );
      if (error) {
        console.error(`Error: ${error}`);
        reject(error);
      } else {
        addMatch(store, response.data);
        updateMatches(store, 'selected', response.data);
        await store.actions.uploadVideo(data.file, data.link, response.data);
        resolve();
      }
    } catch (e) {
      reject(e);
    }
  });
};

export const finishMatch = (store, id) => {
  try {
    requester('POST', `/matches/finished/${id}`, store.state.auth.token);
  } catch (e) {
    console.error(e);
  }
};

export const downloadReport = (store, id) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const [, response] = await downloadFile(
        'GET',
        `/matches/${id}/report`,
        store.state.auth.token
      );
      resolve(response);
    } catch (e) {
      console.error(e);
    }
  });
};

export const deleteMatch = (store, id) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async resolve => {
    try {
      const [error] = await requester(
        'DELETE',
        `/matches/${id}`,
        store.state.auth.token
      );
      if (error) {
        console.error(`Error: ${error}`);
        resolve({
          status: 500,
          message:
            'Ocorreu um erro ao deletar a partida. Por favor, tente novamente!',
        });
      } else {
        removeMatch(store, id);
        resolve({
          status: 200,
          message: 'Partida deletada com sucesso!',
        });
      }
      resolve();
    } catch (e) {
      resolve({
        status: 500,
        message:
          'Ocorreu um erro ao deletar a partida. Por favor, tente novamente!',
      });
    }
  });
};
