import axios from 'axios';
import { baseURL } from './config';

const videoUpload = async (method, endpoint, token = null, videoData) => {
  const formData = new FormData();
  formData.append('file', videoData);

  const config = {
    method,
    baseURL,
    url: endpoint,
    data: formData,
  };

  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
      'Content-type': 'multipart/form-data',
    };
  }

  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.message);
    }
  );

  return axios
    .request(config)
    .then(response => [null, response])
    .catch(error => [error]);
};

export default videoUpload;
