import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useGlobal from './store';
import Content from './layout/Content';
// import TransferDialog from './components/dialogs/TransferDialog.component';
import Loader from './components/dialogs/Loader.component';
import SearchField from './components/field/SearchField.component';
import requester from './resources/requester';

toast.configure();

const App = () => {
  const [globalState, globalActions] = useGlobal();
  const { token } = globalState.auth;
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(null);

  useEffect(() => {
    (async () => {
      const auth = localStorage.getItem('auth')
        ? JSON.parse(localStorage.getItem('auth'))
        : {};

      if (auth?.token) {
        const endpoint = '/auth/validate-token';
        const [error, response] = await requester('GET', endpoint, auth.token);
        if (error) {
          setIsUserLoggedIn(false);
        }
        if (response) {
          globalActions.setAuthByLogin(auth);
          setIsUserLoggedIn(true);
        }
      } else {
        setIsUserLoggedIn(false);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { pathname: pathName } = window.location;

  // eslint-disable-next-line no-shadow
  function isPathPublic(pathName, publicRoutes) {
    return !publicRoutes.includes(pathName);
  }

  const isChangePasswordPublic =
    pathName.split('/')[1] === 'change-password' &&
    Boolean(pathName.split('/')[2]);

  const publicRoutes = ['/login', '/forgot-password', '/cadastro'];
  if (isChangePasswordPublic) {
    publicRoutes.push(pathName);
  }

  if (isUserLoggedIn === false && isPathPublic(pathName, publicRoutes)) {
    window.location.replace('/login');
  }

  if (isUserLoggedIn && !isPathPublic(pathName, publicRoutes)) {
    window.location.replace('/analise-partida');
  }

  return (
    <>
      {!isUserLoggedIn && isPathPublic(pathName, publicRoutes) ? (
        <div
          // eslint-disable-next-line react-native/no-inline-styles
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      ) : (
        <>
          {isUserLoggedIn && isPathPublic(pathName, publicRoutes) ? (
            <SearchField pathName={pathName} />
          ) : (
            false
          )}
          <Content />
          {/* <TransferDialog /> */}
          <Loader />
        </>
      )}
    </>
  );
};

export default App;
