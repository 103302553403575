import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Loader from 'react-loader-spinner';

import useGlobal from '../../store';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: '9999',
    // ao carregar muda a cor
    background: '#FFF',
  },
  container: {
    paddingTop: '50%',
    paddingLeft: '50%',
  },
}));

export default function LoaderComponent() {
  const [globalState] = useGlobal();
  const { showLoader } = globalState.ui;

  useEffect(() => {
    if (showLoader) document.getElementById('app').style.overflow = 'hidden';
    else document.getElementById('app').style.overflow = 'unset';
  }, [showLoader]);

  const classes = useStyles();

  if (globalState.ui.showLoader) {
    return (
      <Grid className={classes.root}>
        <Grid className={classes.container}>
          <Loader
            className="loader"
            type="BallTriangle"
            color="#A1C6EA"
            height={80}
            width={80}
          />
        </Grid>
      </Grid>
    );
  } else {
    return '';
  }
}
