import React from 'react';
import Button from '@material-ui/core/Button';

const PrimaryButton = ({
  icon,
  color,
  text,
  variant,
  type,
  onClick,
  className,
  style,
  disabled,
}) => (
  <Button
    variant={variant}
    color={color}
    type={type}
    onClick={onClick}
    startIcon={icon}
    className={className}
    style={style}
    disabled={disabled}
  >
    {text}
  </Button>
);

export default PrimaryButton;
