import requester from '../resources/requester';

const setAnalysis = (store, analysisOptions) => {
  const analysis = {
    ...store.state.analysis,
    options: {
      home: analysisOptions.home,
      away: analysisOptions.away,
    },
    signedUrl: analysisOptions.signedUrl,
  };
  store.setState({ ...store.state, analysis });
};

const setEvents = (store, events) => {
  const analysis = {
    ...store.state.analysis,
    events,
  };
  store.setState({ ...store.state, analysis });
};

export const resetAnalysisState = (store, analysisOptions) => {
  const analysis = {
    ...store.state.analysis,
    options: [],
    events: [],
    signedUrl: '',
  };
  store.setState({ ...store.state, analysis });
};

export const fetchAnalysis = async (store, matchId) => {
  const url = `/analysis/match/${matchId}`;
  const [error, response] = await requester('GET', url, store.state.auth.token);
  if (error) {
    console.error(JSON.stringify(error));
  } else {
    setAnalysis(store, response.data);
  }
};

export const fetchEvents = async (store, matchId) => {
  const url = `/matches/${matchId}/events`;
  const [error, response] = await requester('GET', url, store.state.auth.token);
  if (error) {
    console.error(JSON.stringify(error));
  } else {
    setEvents(store, response.data);
  }
};

export default null;
