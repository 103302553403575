import requester from '../resources/requester';

const setTeamConfigurations = (store, teamConfigurationsData) => {
  const teamConfigurations = {
    general: teamConfigurationsData.teamConfiguration,
    players: teamConfigurationsData.players,
  };
  store.setState({ ...store.state, teamConfigurations });
};

const setUpdateTeamConfigurations = (store, teamConfiguration) => {
  const teamConfigurations = {
    ...store.state.teamConfigurations,
    general: teamConfiguration,
  };
  store.setState({ ...store.state, teamConfigurations });
};

const addPlayer = (store, player) => {
  const filteredOptions = store.state.teamConfigurations.players.filter(
    p => p.id !== player.id
  );
  const teamConfigurations = {
    ...store.state.teamConfigurations,
    players: [...filteredOptions, player],
  };
  store.setState({ ...store.state, teamConfigurations });
};

const removePlayer = (store, id) => {
  const filteredOptions = store.state.teamConfigurations.players.filter(
    p => p.id !== id
  );
  const teamConfigurations = {
    ...store.state.teamConfigurations,
    players: [...filteredOptions],
  };
  store.setState({ ...store.state, teamConfigurations });
};

export const fetchTeamConfigurations = store => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const [error, response] = await requester(
      'GET',
      '/team/configuration/all-teams',
      store.state.auth.token
    );
    if (error) {
      console.error(JSON.stringify(error));
      resolve([]);
    } else {
      setTeamConfigurations(store, response.data);
      resolve(response.data);
    }
  });
};

export const saveTeamWithPlayers = async (store, teamData) => {
  try {
    const { teamConfiguration, players } = teamData;

    const teamConfigurationDTO = {
      teamConfiguration: {
        name: teamConfiguration.name,
        abbreviation: teamConfiguration.abbreviation,
        description: teamConfiguration.description,
        season: teamConfiguration.season,
        logo: teamConfiguration.logo,
      },
      players,
    };

    const [error, response] = await requester(
      'POST',
      '/team/configuration/saveWithPlayers',
      store.state.auth.token,
      teamConfigurationDTO
    );

    if (error) {
      console.error(JSON.stringify(error));
      return {
        status: 500,
        message: `Ocorreu um erro ao salvar a equipe com os jogadores.${error}`,
      };
    } else {
      return {
        status: 200,
        message: 'Equipe com jogadores salva com sucesso!',
      };
    }
  } catch (e) {
    console.error('Ocorreu um erro:', e);
    return {
      status: 500,
      message: `Ocorreu um erro ao salvar a equipe com os jogadores.${e}`,
    };
  }
};

export const updateTeamConfigurations = (store, data) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const [error, response] = await requester(
      'POST',
      '/team/configuration',
      store.state.auth.token,
      data
    );
    if (error) {
      console.error(JSON.stringify(error));
      resolve([]);
    } else {
      setUpdateTeamConfigurations(store, response.data);
      resolve(response.data);
    }
  });
};

export const deletePlayer = (store, id) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async resolve => {
    try {
      const [error] = await requester(
        'DELETE',
        `/players/${id}`,
        store.state.auth.token
      );
      if (error) {
        console.error(`Error: ${error}`);
        resolve({
          status: 500,
          message:
            'Ocorreu um erro ao deletar o jogador. Por favor, tente novamente!',
        });
      } else {
        removePlayer(store, id);
        resolve({
          status: 200,
          message: 'Jogador deletada com sucesso!',
        });
      }
      resolve();
    } catch (e) {
      resolve({
        status: 500,
        message:
          'Ocorreu um erro ao deletar o jogador. Por favor, tente novamente!',
      });
    }
  });
};
