/* eslint-disable prefer-template */
import React from 'react';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import './index.css';
import requester from '../../resources/requester';

const SignIn = () => {
  const [form, setForm] = React.useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    team: '',
    sport: '',
    phone: '',
  });

  const inputs = [
    {
      label: 'Nome completo',
      name: 'name',
    },
    {
      label: 'E-mail',
      name: 'email',
      type: 'email',
    },
    {
      label: 'Senha',
      name: 'password',
    },
    {
      label: 'Confirme Senha',
      name: 'confirmPassword',
    },
    {
      label: 'Time',
      name: 'team',
    },
    {
      label: 'Esporte',
      name: 'sport',
    },
    {
      label: 'Telefone',
      name: 'phone',
      type: 'tel',
    },
  ];

  const [response, setResponse] = React.useState('');

  const handleSubmit = async event => {
    event.preventDefault();

    if (form.password !== form.confirmPassword) {
      setResponse('As senhas não correspodem');
      return;
    }
    try {
      const [error, apiResponse] = await requester(
        'POST',
        '/auth/signup',
        null,
        form
      );

      if (error) {
        console.error(error);
        setResponse(
          'Erro ao enviar os dados. Verifique sua conexão ou tente novamente mais tarde.'
        );
      } else {
        setResponse(
          'Dados enviados com sucesso! Verifique seu email cadastrado'
        );
      }
    } catch (error) {
      setResponse(
        'Erro na requisição. Verifique sua conexão ou tente novamente mais tarde.'
      );
    }
  };

  function handleChange({ target }) {
    const { id, value } = target;
    setForm({ ...form, [id]: value });
  }

  return (
    <Card className="signin_card">
      <Typography className="titulo" variant="h5" component="h2">
        Cadastro
      </Typography>
      <form className="root" onSubmit={handleSubmit}>
        {inputs.map(currentInput => (
          <div key={currentInput.name} className="input">
            <span for={currentInput.name}>{currentInput.label}</span>
            <TextField
              id={currentInput.name}
              variant="outlined"
              name={currentInput.name}
              value={form[currentInput.name]}
              size="small"
              onChange={e => {
                handleChange(e);
              }}
            />
          </div>
        ))}
        {response && <p>{response}</p>}
        <button>Cadastrar</button>
      </form>
    </Card>
  );
};

export default SignIn;
