/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Loader from 'react-loader-spinner';
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Tabs,
  Tab,
  Typography,
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import MaterialTable from 'material-table';
import axios from 'axios';
import useGlobal from '../store';
import { language } from '../languages';

import DefaultDialog from '../components/dialogs/DefaultDialog.component';
import FormDialog from '../components/dialogs/FormDialog.component';
import PrimaryButton from '../components/buttons/PrimaryButton.component';
import TeamRow from '../components/TeamsConfigs/TeamRow';
import AddTeamButton from '../components/TeamsConfigs/AddTeamButton';
import requester from '../resources/requester';
import { baseURL } from '../resources/config';
import { error, success } from '../utils/toast';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: '12vh 0 0 0',
  },
  containerRoot: {
    border: '1px solid',
    borderRadius: 5,
  },
  textField: {
    width: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    marginLeft: 8,
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  input: {
    display: 'none',
  },
  contentClassName: {
    height: 200,
  },
}));

const TabPanel = ({ children, value, index, ...other }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <Box p={3}>{children}</Box>}
  </Typography>
);

const a11yProps = index => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

//  Define um objeto com valores padrão para uma linha de dados.
const defaultRowData = {
  id: null,
  name: '',
  email: '',
};
// função de comparação usada para ordenar jogadores com base em seus nomes.
const orderPlayers = (a, b) => {
  if (a.name > b.name) return 1;
  return -1;
};

// listar jogadores
const ViewTeams = () => {
  const [globalState, globalActions] = useGlobal();
  const {
    fetchTeamConfigurations,
    createUpdatePlayer,
    deletePlayer,
    updateTeamConfigurations,
  } = globalActions;

  const [teamConfigurations, setTeamConfigurations] = useState([{}]);
  const { auth } = globalState;

  const [rowData, setRowData] = useState(defaultRowData);
  const [isLoading, setIsLoading] = useState(true);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    (async () => {
      const teams = await fetchTeamConfigurations();
      if (teams) {
        setTeamConfigurations(teams);
      }
      if (!teams) {
        setTeamConfigurations([]);
      }
      setIsLoading(false);
    })();
  }, []);

  const handleFormChange = name => event =>
    setRowData({ ...rowData, [name]: event.target.value });

  const agreeOnClick = async () => {
    const data = {
      ...rowData,
      teamConfiguration: {
        id: teamConfigurations.id,
      },
    };

    createUpdatePlayer(data);
    setRowData(defaultRowData);
    setOpenDialog(false);
  };

  const disagreeOnClick = () => {
    setOpenDialog(false);
    setRowData(defaultRowData);
  };

  const agreeDeleteOnClick = async () => {
    deletePlayer(rowData.id);
    setOpenDeleteDialog(false);
  };

  const handlerActionClick = (actionType, rowData) => {
    if (actionType === 'DELETE') {
      setRowData(rowData);
      setOpenDeleteDialog(true);
    } else {
      setRowData(rowData);
      setOpenDialog(true);
    }
  };

  const handleTeamDelete = async id => {
    const { status } = await axios.request({
      method: 'DELETE',
      url: `${baseURL}/team/configuration/${id}`,
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    });

    if (status === 200) {
      setTeamConfigurations(prevState =>
        prevState.filter(team => team.id !== id)
      );
      success('Time deletado com sucesso!');
    } else {
      error('Erro ao deletar time');
    }
  };

  return (
    <section
      style={{
        width: '100dvw',
        padding: '0 16px',
      }}
    >
      <div className={classes.root}>
        <h1
          style={{
            width: 'fit-content',
            padding: '1em 1em 0.25em 1em',
            borderBottom: '4px solid #a1c6ea',
            borderRadius: '4px',
          }}
        >
          Times
        </h1>
        {isLoading ? (
          <Loader
            style={{
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '28vh',
            }}
            type="BallTriangle"
            color="#A1C6EA"
            height={80}
            width={80}
          />
        ) : (
          <ul
            style={{
              padding: '2vh 0',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <button
              type="button"
              className="addTeamButton"
              onClick={() => {
                window.location.replace('../team/create');
              }}
            >
              Adicionar time
            </button>
            <li
              style={{
                listStyleType: 'none',
              }}
            >
              {teamConfigurations
                .filter(team => team.id)
                .map((currentTeam, index) => (
                  <TeamRow
                    team={currentTeam}
                    key={currentTeam.name}
                    onTeamDelete={handleTeamDelete}
                    visualize
                  />
                ))}
            </li>
          </ul>
        )}
      </div>
      {/* <Tabs
        value={value}
        onChange={handlerTabChange}
        indicatorColor="primary"
        variant="fullWidth"
      >
        <Tab label={language.configurations.information} {...a11yProps(0)} />
        <Tab label={language.configurations.players} {...a11yProps(1)} />
      </Tabs> */}
      {/* <TabPanel value={value} index={0}>
        <ValidatorForm
          onSubmit={() => {
            updateTeamConfigurations(teamConfigurations);
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={4} sm={2}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                <Grid item>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="icon-button-file"
                    type="file"
                    onChange={onChange}
                  />
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <Avatar
                        className={classes.avatar}
                        src={teamConfigurations.logo}
                      />
                    </IconButton>
                  </label>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextValidator
                id="outlined-title"
                label={language.configurations.team}
                className={classes.textField}
                value={teamConfigurations.name}
                onChange={handleChange('name')}
                margin="normal"
                variant="outlined"
                validators={['required', 'maxStringLength:50']}
                errorMessages={[
                  language.newMatch.required,
                  language.newMatch.validation.maxLength50,
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <InputMask
                mask="aaa"
                value={teamConfigurations.abbreviation}
                onChange={handleChange('abbreviation')}
              >
                {() => (
                  <TextValidator
                    id="outlined-home"
                    label={language.configurations.acronym}
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    validators={['required', 'matchRegexp:^[A-Z]{3}$']}
                    errorMessages={[
                      language.newMatch.required,
                      language.newMatch.validation.teamAcronym,
                    ]}
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                id="outlined-description"
                label={language.newMatch.description}
                className={classes.textField}
                value={teamConfigurations.description}
                onChange={handleChange('description')}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <PrimaryButton
                variant="outlined"
                color="primary"
                className={classes.button}
                text={language.configurations.save}
                type="submit"
              />
            </Grid>
          </Grid>
        </ValidatorForm>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MaterialTable
          title=""
          columns={columns}
          data={players}
          options={{
            actionsColumnIndex: -1,
            pageSize: 10,
            pageSizeOptions: [10, 20],
            headerStyle: {
              backgroundColor: 'gray',
              color: '#FFF',
            },
          }}
          actions={[
            {
              icon: 'edit',
              tooltip: 'Editar',
              onClick: (_, rowData) => handlerActionClick('EDIT', rowData),
            },
            {
              icon: 'delete',
              tooltip: 'Deletar',
              onClick: (_, rowData) => handlerActionClick('DELETE', rowData),
            },
            {
              icon: 'add',
              tooltip: 'Adicionar',
              isFreeAction: true,
              onClick: () => handlerActionClick('ADD', defaultRowData),
            },
          ]}
          localization={{
            pagination: {
              labelDisplayedRows:
                language.compiled.pagination.labelDisplayedRows,
              labelRowsSelect: language.compiled.pagination.labelRowsSelect,
              labelRowsPerPage:
                language.users.table.pagination.labelRowsPerPage,
              firstAriaLabel: language.compiled.pagination.firstAriaLabel,
              firstTooltip: language.compiled.pagination.firstTooltip,
              previousAriaLabel: language.compiled.pagination.previousAriaLabel,
              previousTooltip: language.compiled.pagination.previousTooltip,
              nextAriaLabel: language.compiled.pagination.nextAriaLabel,
              nextTooltip: language.compiled.pagination.nextTooltip,
              lastAriaLabel: language.compiled.pagination.lastAriaLabel,
              lastTooltip: language.compiled.pagination.lastTooltip,
            },
            header: {
              actions: language.compiled.actions,
            },
            body: {
              emptyDataSourceMessage:
                language.users.table.body.emptyDataSourceMessage,
              filterRow: {
                filterTooltip: language.compiled.body.filterRow.filterTooltip,
              },
            },
            toolbar: {
              searchTooltip: language.compiled.toolbar.searchTooltip,
              searchPlaceholder: language.compiled.toolbar.searchPlaceholder,
            },
          }}
        />
      </TabPanel> */}
      <DefaultDialog
        title={language.matchCard.dialog.title}
        description={language.matchCard.dialog.description}
        agreeText={language.matchCard.dialog.agreeText}
        agreeOnClick={agreeDeleteOnClick}
        disagreeText={language.matchCard.dialog.disagreeText}
        disagreeOnClick={() => setOpenDeleteDialog(false)}
        open={openDeleteDialog}
      />
      <FormDialog
        agreeOnClick={agreeOnClick}
        contentClassName={classes.contentClassName}
        disagreeOnClick={disagreeOnClick}
        open={openDialog}
        title={language.configurations.formTitle}
      >
        <ValidatorForm onSubmit={() => {}}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12}>
              <TextValidator
                id="outlined-title"
                label={language.commons.name}
                className={classes.textField}
                value={rowData.name}
                onChange={handleFormChange('name')}
                margin="normal"
                variant="outlined"
                validators={['required', 'maxStringLength:50']}
                errorMessages={[
                  language.newMatch.required,
                  language.newMatch.validation.maxLength50,
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                id="outlined-title"
                label={language.commons.email}
                className={classes.textField}
                value={rowData.email}
                onChange={handleFormChange('email')}
                margin="normal"
                variant="outlined"
                validators={['required', 'maxStringLength:50']}
                errorMessages={[
                  language.newMatch.required,
                  language.newMatch.validation.maxLength50,
                ]}
              />
            </Grid>
          </Grid>
        </ValidatorForm>
      </FormDialog>
    </section>
  );
};

export default ViewTeams;
