/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import axios from 'axios';
import style from './index.css';
import Trash from '../../../assets/icons/trash.svg';
import Camera from '../../../assets/icons/camera.svg';
import toast, { success, error } from '../../../utils/toast';
import useGlobal from '../../../store';
import PlayersModal from '../PlayersModal';
import { baseURL } from '../../../resources/config';

export default function TeamRow({
  team,
  visualize = false,
  onTeamDelete,
  full = false,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [players, setPlayers] = useState([]);
  const [teamData, setTeamData] = useState(team);
  const [globalState, globalActions] = useGlobal();
  const { saveTeamWithPlayers } = globalActions;
  const { auth } = globalState;

  const uploadPlayers = playersCallBack => {
    setPlayers(playersCallBack);
  };

  const labels = [
    {
      description: 'Nome do time',
      name: 'name',
    },
    {
      description: 'Sigla',
      name: 'abbreviation',
    },
    {
      description: 'Temporada',
      name: 'season',
    },
    {
      description: 'Descrição',
      name: 'description',
    },
  ];

  const showPlayersModal = async () => {
    if (team.id) {
      const { data, status } = await axios.request({
        method: 'GET',
        url: `${baseURL}/players/team/${team.id}`,
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      if (status === 200) {
        setPlayers(
          data.map(row => ({
            ...row,
            isCreated: true,
          }))
        );
      }
    }
    setIsModalVisible(prevState => !prevState);
  };

  const saveTeam = async (teamData, players) => {
    if (!teamData || typeof teamData !== 'object') {
      console.error(
        'Erro: TeamData ou players estão indefinidos ou em um formato inválido.'
      );
      return;
    }
    const teamConfiguration = {
      name: teamData.name,
      abbreviation: teamData.abbreviation,
      description: teamData.description,
      season: teamData.season,
      logo: teamData.logo || '',
    };

    const formattedPlayers = players.map(player => ({
      name: player.name,
      gamePosition: player.gamePosition,
      numberPlayed: player.numberPlayed,
    }));

    const dataToSave = {
      teamConfiguration,
      players: formattedPlayers,
    };

    const response = await saveTeamWithPlayers(dataToSave);
    if (response && response.status !== 200) {
      error(response.message);
    } else if (response.status === 200) {
      success('O time foi cadastrado!');
      setTimeout(() => {
        window.location.replace('../teams');
      }, 1500);
    }
  };

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        setTeamData(prevData => ({
          ...prevData,
          logo: reader.result,
        }));
      reader.onerror = error => reject(error);
    });

  const handleTeamData = async () => {
    if (teamData.id) {
      const { status } = await axios.request({
        method: 'PUT',
        url: `${baseURL}/team/configuration/${teamData.id}`,
        data: teamData,
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      if (status === 200) {
        success(`O time ${teamData.name} foi atualizado!`);
      }
      if (status !== 200) {
        success('Não foi possível atualizar!');
      }
    }
    if (!teamData.id) {
      saveTeam(teamData, players);
    }
  };

  return (
    <>
      {isModalVisible ? (
        <PlayersModal
          team={teamData}
          data={players}
          uploadPlayers={uploadPlayers}
          onClose={() => setIsModalVisible(false)}
        />
      ) : (
        false
      )}
      <div className={`container ${full ? 'full' : ''}`}>
        <div className="file-upload">
          {teamData.logo ? <img src={teamData.logo} alt="team logo" /> : false}
          {visualize ? (
            false
          ) : (
            <>
              {' '}
              <input type="file" onChange={e => toBase64(e.target.files[0])} />
              <div>
                <img src={Camera} alt="Camera icon" />
              </div>
            </>
          )}
        </div>
        <div className="data-container">
          {labels.map(currentLabel => (
            <div
              key={currentLabel.name}
              className={`input ${currentLabel.name}`}
            >
              <label htmlFor={currentLabel.name}>
                {currentLabel.description}
              </label>
              {visualize ? (
                <span>{teamData[currentLabel.name]}</span>
              ) : (
                <input
                  type="text"
                  id={currentLabel.name}
                  defaultValue={teamData ? teamData[currentLabel.name] : null}
                  value={teamData[currentLabel.name]}
                  onChange={e => {
                    e.persist();
                    setTeamData(prevState => {
                      return {
                        ...prevState,
                        [currentLabel.name]: e.target.value,
                      };
                    });
                  }}
                />
              )}
            </div>
          ))}
          <div className="buttons">
            {visualize ? (
              <a className="button-visualize" href={`../team/${team.id}`}>
                Editar
              </a>
            ) : (
              false
            )}
            <button
              type="button"
              className="button-config"
              onClick={showPlayersModal}
            >
              Jogadores
            </button>

            {visualize ? (
              <button
                type="button"
                className="button-trash"
                onClick={() => onTeamDelete(team.id)}
              >
                <img src={Trash} alt="Trash icon" />
              </button>
            ) : (
              false
            )}
            {!visualize ? (
              <button
                type="button"
                className="button-save"
                onClick={handleTeamData}
              >
                Salvar
              </button>
            ) : (
              false
            )}
          </div>
        </div>
      </div>
    </>
  );
}
