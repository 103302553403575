/*
  Definitions:
    - Event name :: CamelCase (front-end)
    - Event type :: dashed (back-end)
*/

export const eventsHandler = event => {
  let upper = false;
  return event
    .split('')
    .map((letter, index) => {
      if (upper) {
        upper = false;
        return letter.toUpperCase();
      }
      if (letter === '-') {
        upper = true;
        return '';
      } else {
        return letter;
      }
    })
    .join('');
};

export default null;
